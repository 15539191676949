import React from 'react';
import { Alert, VerticalGroup } from '@grafana/ui';

interface DeployableDemoCapacityAlertProps {
  // Define props here
}

const DeployableDemoCapacityAlert: React.FC<DeployableDemoCapacityAlertProps> = (props) => {
  // Implement component logic here

  return (
    <div>
      {
        <Alert severity="warning" title="Application Environment capacity reached" buttonContent="henlo">
          <VerticalGroup>
            <div>
              Sorry, we&apos;re full for Application Environments! You will not be able to create a new one, as our capacity has been
              exhausted. If you have provisioned a Application Environment which you are no longer using, please consider deleting it to 
              free up capacity for others. Reach out to <code>#field-engineering</code> in Slack for help.{' '}
            </div>
          </VerticalGroup>
        </Alert>
      }
    </div>
  );
};

export default DeployableDemoCapacityAlert;
