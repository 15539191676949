// src/api/workshopAPI.ts
import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { Workshop } from 'types/workshop';
import { Template } from 'types/template';
import { StatusImpl } from 'types/status';
import { getBackendSrv } from '@grafana/runtime';

const client = async (): Promise<AxiosInstance> => {
  try {
    // Make an API call to retrieve plugin settings
    const response = await getBackendSrv().get('/api/plugins/fieldeng-brokkr-app/settings');
    // Access the apiUrl from the response object
    const apiUrl = response.jsonData.apiUrl;
    const username = response.jsonData.apiUsername;
    const password = Cookies.get('feAppApiKey') ?? '';

    // Create and configure the Axios instance using the apiUrl
    const instance = axios.create({
      baseURL: apiUrl, // Set the base URL to the apiUrl in the settings
      timeout: 5000, // Set the request timeout in milliseconds
      headers: {
        'Content-Type': 'application/json',
      },
      auth: {
        username: username, // Set in plugin settings
        password: password, // Set from the user's cookie
      },
    });
    return instance;
  } catch (error) {
    console.error('Error initializing Axios:', error);
    throw error; // Rethrow the error to reject the promise
  }
};

export default client;

export const getWorkshops = async (): Promise<Workshop[]> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/workshops`);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to get workshops. ${errorMessage}`);
  }
};

export const getWorkshop = async (id: string): Promise<Workshop> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}`);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    console.log(error);
    throw new Error(`Failed to get workshop ${id}. ${errorMessage}`);
  }
};

export const createWorkshop = async (workshop: object): Promise<Workshop> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.post(`api/plugins/fieldeng-brokkr-app/resources/workshops`, workshop);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to create workshop: ${errorMessage}`);
  }
};

export const updateWorkshop = async (id: string, workshop: Workshop): Promise<Workshop> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.put(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}`, workshop);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to update workshop ${id}. ${errorMessage}`);
  }
};

export const deleteWorkshop = async (params: any): Promise<string> => {
  const { id, ignoreDate, dbOnly, force } = params;
  try {
    const backendSrv = getBackendSrv();
    await backendSrv.delete(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}`, null, {
      params: {
        ignoreDate: ignoreDate,
        dbOnly: dbOnly,
        force: force,
      },
    });
    return id;
  } catch (error: any) {
    // A toast notification will be displayed here containing the contents of 'message' from the response
    throw new Error(error.message);
  }
};

export const patchWorkshop = async (params: any): Promise<any> => {
  const { id, forceUnlock } = params;
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.patch(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}`, null, {
      params: { forceUnlock: forceUnlock },
    });
    return response;
  } catch (error: any) {
    console.error(`Failed to patch workshop ${id}:`, error.message);
    throw new Error(error.message);
  }
};

export const sendWorkshopEmails = async (id: string): Promise<any> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.post(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}/email`);
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const testWorkshop = async (id: string): Promise<any> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.post(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}/test`);
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const extendWorkshop = async (params: any): Promise<any> => {
  const { id, hours } = params;
  console.log(`Extending workshop ${id} by ${hours} hours`);
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.post(`api/plugins/fieldeng-brokkr-app/resources/workshops/${id}/extend`, {
      hours: hours,
    });
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const cancelWorkshopJob = async (workshopId: string, jobId: string): Promise<any> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.delete(
      `api/plugins/fieldeng-brokkr-app/resources/workshops/${workshopId}/jobs/${jobId}`
    );
    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getTemplates = async (): Promise<Template[]> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/templates`);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to get templates: ${errorMessage}`);
  }
};

export const getSystemStatus = async (): Promise<StatusImpl> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/status`);
    const status = new StatusImpl(response);
    return status;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to get system status: ${errorMessage}`);
  }
};
