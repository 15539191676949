import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  getWorkshop,
  deleteWorkshop,
  patchWorkshop,
  extendWorkshop,
  sendWorkshopEmails,
  testWorkshop,
  cancelWorkshopJob,
} from 'services/workshopAPI';
import { Workshop } from 'types/workshop';
import {
  Button,
  ConfirmModal,
  Modal,
  HorizontalGroup,
  VerticalGroup,
  Tag,
  IconButton,
  LoadingPlaceholder,
  Alert,
  ToolbarButton,
  ToolbarButtonRow,
  Menu,
  Dropdown,
  Checkbox,
  useStyles2,
  InlineField,
  Input,
} from '@grafana/ui';
import { toIconName, AppEvents, GrafanaTheme2 } from '@grafana/data';
import { prefixRoute, ROUTES } from 'utils/routing.util';
import { getWorkshopDetails, WorkshopType } from 'components/ScheduleWorkshopForm/types';
import WorkshopHeader from 'components/WorkshopHeader/WorkshopHeader';
import WorkshopAttendeeDetails from 'components/WorkshopAttendeeDetails/WorkshopAttendeeDetails';
import DeployableDemoHeader from 'components/DeployableDemoHeader/DeployableDemoHeader';
import DeployableDemoUserDetails from 'components/DeployableDemoUserDetails/DeployableDemoUserDetails';
import { isWithin48Hours } from 'utils/workshop.util';
import { PluginPage, getAppEvents } from '@grafana/runtime';
import { AuthenticatedUserContext } from 'utils/usercontext.util';
import { hasPermission } from 'utils/auth.util';
import { css } from '@emotion/css';

interface RouteParams {
  id: string;
}

// WorkshopDetails component displays detailed information about a selected workshop
const WorkshopDetails: React.FC = () => {
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRebuildModal, setShowRebuildModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [showForceDeleteModal, setShowForceDeleteModal] = useState(false);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [workshopDetails, setWorkshopDetails] = useState<WorkshopType | null>(null);
  const [showExtraConfirmModal, setShowExtraConfirmModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isForceUnlock, setIsForceUnlock] = useState(false);
  const [extendHours, setExtendHours] = useState(24);
  const [isDeployableDemo, setIsDeployableDemo] = useState(false);
  const authenticatedUser = useContext(AuthenticatedUserContext);
  const appEvents = getAppEvents();
  const styles = useStyles2(getStyles);

  // Fetch workshop data on component mount
  useEffect(() => {
    (async () => {
      try {
        const data = await getWorkshop(id);

        setWorkshop(data);
        setIsDeployableDemo(data.type.startsWith('deployabledemo'));
        let workshopdeets = getWorkshopDetails(data.type);
        setWorkshopDetails(workshopdeets ?? null); // pass null if workshopdeets is undefined

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching workshop details:', error);
        setIsLoading(false);
      }
    })();
  }, [id, refreshKey]);

  const handleRebuild = async () => {
    setShowRebuildModal(false);

    try {
      await patchWorkshop({ id, forceUnlock: isForceUnlock });
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Workshop rebuild requested. Please allow up to 2 hours to complete.'],
      });
      setTimeout(() => {
        setRefreshKey(refreshKey + 1);
      }, 1000);
    } catch (error) {
      console.error('Error requesting rebuild of workshop:', error);
    }
  };

  const handleExtend = async () => {
    try {
      await extendWorkshop({ id, hours: extendHours });
      setShowExtendModal(false);
    } catch (error) {
      console.error('Error extending workshop:', error);
    }
  }

  const handleUnlink = async () => {
    setShowUnlinkModal(false);

    try {
      await deleteWorkshop({ id: id, ignoreDate: true, dbOnly: true });
      history.push(prefixRoute(ROUTES.Environments));
    } catch (error) {
      console.error('Error unlinking workshop:', error);
    }
  };

  const handleEmail = async () => {
    setShowEmailModal(false);

    try {
      await sendWorkshopEmails(id);
    } catch (error) {
      console.error('Error sending email to all users:', error);
    }
  };

  const handleTest = async () => {
    setShowTestModal(false);

    try {
      await testWorkshop(id);
    } catch (error) {
      console.error('Error scheduling tests:', error);
    }
  };

  const handleCancelJob = async () => {
    if (!workshop?.jobId) {
      return;
    }
    setShowCancelJobModal(false);

    try {
      await cancelWorkshopJob(id, workshop.jobId);
    } catch (error) {
      console.error('Error cancelling job:', error);
    }
  };

  const handleForceUnlockChange = (e: any) => {
    setIsForceUnlock(e.currentTarget.checked);
  };

  const handleExtendHoursChange = (e: any) => {
    if (isNaN(Number(e.currentTarget.value))) {
      return;
    }
    setExtendHours(e.currentTarget.value);
  }

  // Handle workshop deletion
  const handleDelete = async ({
    ignoreDate,
    dbOnly,
    force,
  }: {
    ignoreDate: boolean;
    dbOnly: boolean;
    force: boolean;
  }) => {
    if (workshop?.scheduledDate && isWithin48Hours(new Date(workshop.scheduledDate)) && !ignoreDate) {
      if (!showExtraConfirmModal) {
        setShowExtraConfirmModal(true);
        setShowDeleteModal(false);
      } else {
        history.push(prefixRoute(ROUTES.Environments));
      }
    } else {
      setShowDeleteModal(false);
      setShowExtraConfirmModal(false);

      try {
        await deleteWorkshop({
          id: id,
          ignoreDate: true,
          dbOnly: dbOnly ? 'true' : 'false',
          force: force ? 'true' : 'false',
        });
        // This will automatically create a Toast alert on success or failure
        history.push(prefixRoute(ROUTES.Environments));
      } catch (error) {
        // If the delete failed, don't navigate away from this page
        console.error('Error deleting workshop:', error);
      }
    }
  };

  const iconButtonsData = [
    {
      tooltip: 'Logistics document',
      name: 'file-blank',
      onClick: () => window.open(`${workshopDetails?.doc}`, '_blank'),
    },
    {
      tooltip: 'Presentation',
      name: 'presentation-play',
      onClick: () => window.open(`${workshopDetails?.gdrive}`, '_blank'),
    },
    {
      tooltip: 'GitHub repository for breakouts',
      name: 'github',
      onClick: () => window.open(`${workshopDetails?.github}`, '_blank'),
    },
    {
      tooltip: 'Slack channel',
      name: 'slack',
      onClick: () => window.open(`${workshopDetails?.slack}`, '_blank'),
    },
  ];

  const contextMenu = (
    <Menu>
      <Menu.Item
        label="Send user logins"
        onClick={() => setShowEmailModal(true)}
        icon="envelope"
        disabled={
          !(authenticatedUser && hasPermission(authenticatedUser, 'environment:email')) ||
          !(workshop?.state?.toLowerCase() === 'provisioned')
        } // Disable the button if workshop state is not provisioned
      />
      <Menu.Divider />
      <Menu.Item
        label="Run tests"
        onClick={() => setShowTestModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:test'))}
      />
      <Menu.Divider />
      <Menu.Item
        label="Abort current job"
        onClick={() => {
          setShowCancelJobModal(true);
        }}
        disabled={
          !(authenticatedUser && hasPermission(authenticatedUser, 'environment:abort-job')) ||
          workshop?.jobId === null ||
          workshop?.state === 'aborted'
        }
      />
      <Menu.Item
        label="Extend lifetime"
        onClick={() => setShowExtendModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:extend'))}
      />
      <Menu.Item
        label="Rebuild"
        onClick={() => setShowRebuildModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:rebuild'))}
      />
      <Menu.Divider />
      <Menu.Item
        label="Unlink"
        onClick={() => setShowUnlinkModal(true)}
        destructive
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:unlink'))}
      />
      <Menu.Item
        label="Force delete"
        onClick={() => {
          setShowForceDeleteModal(true);
        }}
        destructive
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:force-delete'))}
      />
    </Menu>
  );

  return (
    <PluginPage
      info={[
        {
          label: 'Created by',
          value: workshop?.leadSEAttendee?.name || '...',
        },
        {
          label: 'Type',
          value: <Tag className="custom-tag" name={workshopDetails?.value || workshop?.type || 'unknown'}></Tag>
        }
      ]}
      pageNav={{
        text: workshop?.description || workshop?.name || '...',
        subTitle: `ID: ${workshop?.name}` || '',
        img: "/public/img/grafana_icon.svg",
        // hideFromBreadcrumbs: true,
      }}
      actions={
        <HorizontalGroup spacing="md">
          <ToolbarButtonRow>
            <ToolbarButton
              icon="trash-alt"
              variant="destructive"
              type="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={(
                !(authenticatedUser && hasPermission(authenticatedUser, 'environment:delete')) ||
                workshop?.state?.toLowerCase() === 'provisioning'
              )} // Disable the button if workshop state is provisioning
              tooltip="Delete workshop and all infrastructure"
            >
              Delete
            </ToolbarButton>
            <Dropdown overlay={contextMenu} placement="bottom-end">
              <IconButton name="ellipsis-v" 
                aria-label='More actions'
              size="xl" variant="secondary" onClick={() => {}} />
            </Dropdown>
          </ToolbarButtonRow>
        </HorizontalGroup>
      }
    >
      {isLoading ? (
        <LoadingPlaceholder text="Loading workshop details..." />
      ) : (
        <div>
          {workshop ? (
            <div>
              <div className={styles.workshopResources}>
                <HorizontalGroup justify="flex-end" align='center' spacing='md'>
                  {iconButtonsData.map((buttonData, index) => (
                    <IconButton
                      key={index}
                      tooltip={buttonData.tooltip}
                      name={toIconName(buttonData.name) ?? 'circle'}
                      size="xl"
                      variant="secondary"
                      onClick={buttonData.onClick}
                    />
                  ))}
                </HorizontalGroup>
              </div>
              { isDeployableDemo === false && (
                <div>
                  <WorkshopHeader workshop={workshop} />
                  <WorkshopAttendeeDetails workshop={workshop} />
                </div>
              )}
              { isDeployableDemo === true && (
                <div>
                  <DeployableDemoHeader workshop={workshop} />
                  <DeployableDemoUserDetails workshop={workshop} />
                </div>
              )}
              
              <VerticalGroup spacing="md">
                <div>
                  <ConfirmModal
                    isOpen={showDeleteModal}
                    title="Delete Workshop"
                    body="Are you sure you want to delete this workshop?"
                    confirmText="Delete"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: false, force: false });
                    }}
                    onDismiss={() => {
                      setShowDeleteModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showExtraConfirmModal}
                    title="Are you sure?"
                    body="This workshop is scheduled within the next 48 hours. Are you sure you want to delete it? There is no guarantee you will be able to reprovision it in time."
                    confirmText="Yes, delete it"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: true, force: false });
                    }}
                    onDismiss={() => {
                      setShowExtraConfirmModal(false);
                    }}
                  />
                </div>
                <div>
                  <Modal
                    isOpen={showRebuildModal}
                    title="Rebuild this environment?"
                    onDismiss={() => setShowRebuildModal(false)}
                  >
                    <>
                      <VerticalGroup spacing="md">
                        <div>
                          <p>
                            Are you sure you want to rebuild this environment? This action will check the state of the
                            environment, and attempt to recreate any missing or broken resources.
                          </p>
                          <p>This process may take a couple of hours to complete.</p>
                        </div>
                        {authenticatedUser && hasPermission(authenticatedUser, 'environment:force-unlock') && (
                          <Checkbox
                            value={isForceUnlock}
                            label={'Forcibly unlock Terraform state before building'}
                            onChange={handleForceUnlockChange}
                            description="Check this only if you are sure that another job is not currently running"
                          />
                        )}
                        <HorizontalGroup justify="flex-end">
                          <Button variant="secondary" onClick={() => setShowRebuildModal(false)}>
                            Cancel
                          </Button>
                          <Button
                            variant="destructive"
                            onClick={() => {
                              handleRebuild();
                            }}
                          >
                            Rebuild environment
                          </Button>
                        </HorizontalGroup>
                      </VerticalGroup>
                    </>
                  </Modal>
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showUnlinkModal}
                    title="Do you want to unlink this workshop?"
                    body="This operation removes this workshop from this application, and disconnects it from any infrastructure which been created. This operation cannot be undone. (If you're in doubt, this probably isn't what you want to do.)"
                    confirmText="Unlink"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleUnlink();
                    }}
                    onDismiss={() => {
                      setShowUnlinkModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showEmailModal}
                    title="Send login details to users?"
                    body="This operation will send an email to all users with their login details. Are you sure you want to proceed?"
                    confirmText="Send"
                    confirmVariant="primary"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleEmail();
                    }}
                    onDismiss={() => {
                      setShowEmailModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showTestModal}
                    title="Test workshop?"
                    body="This operation will run end-to-end automated tests for each user in the workshop environment. Are you sure you want to proceed?"
                    confirmText={`Test all ${
                      (workshop.workshopAttendees?.length ?? 0) + (workshop.breakoutSEAttendees?.length ?? 0) + 1
                    } users`}
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleTest();
                    }}
                    onDismiss={() => {
                      setShowTestModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showCancelJobModal}
                    title="Abort current job?"
                    body="This operation will abort the current provisioning job and may result in a broken workshop. Are you sure you want to proceed?"
                    confirmText="Abort job"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleCancelJob();
                    }}
                    onDismiss={() => {
                      setShowCancelJobModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showForceDeleteModal}
                    title="Force delete?"
                    body="This operation will force delete the workshop and all infrastructure. Are you sure you want to proceed?"
                    confirmText="Force delete"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: true, force: true });
                    }}
                    onDismiss={() => {
                      setShowForceDeleteModal(false);
                    }}
                  />
                </div>
                <div>
                  <Modal
                    isOpen={showExtendModal}
                    title="Extend environment lifetime?"
                    onDismiss={() => setShowExtendModal(false)}
                  >
                    <VerticalGroup spacing="md">
                      <div>
                        <p>
                          Extend the lifetime of an environment so that it is not automatically deleted.
                        </p>
                      </div>
                      <InlineField label="Extend by (hours)" 
                      invalid={isNaN(Number(extendHours))} 
                        error={'Must be a number'}>
                        <Input placeholder="24" 
                          value={extendHours}
                          onChange={handleExtendHoursChange}
                        />
                      </InlineField>
                      <HorizontalGroup justify="flex-end">
                        <Button variant="secondary" onClick={() => setShowExtendModal(false)}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleExtend();
                          }}
                        >
                          Extend lifetime
                        </Button>
                      </HorizontalGroup>
                    </VerticalGroup>
                  </Modal>
                </div>
              </VerticalGroup>

            </div>
          ) : (
            <Alert title="Workshop not found" severity="error" />
          )}
        </div>
      )}
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  workshopResources: css`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
  `,
});

export default WorkshopDetails;
