import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { createTableTheme } from "components/CustomTable/tableTheme";
import { config } from "@grafana/runtime";
import { Icon, EmptySearchResult } from "@grafana/ui";
import SearchInput from "components/SearchInput/SearchInput"; // Import the SearchInput component
import './pagnation.css';
import {CustomPagination} from "components/CustomTable/pagnation";
interface CustomTableProps {
  key?: string;
  title?: string;
  columns: any[];
  data: any[];
  searchPlaceholder: string;
  itemsPerPage?: number;
  onRowClick?: (row: any) => void;
  selectableRows?: boolean;
  selectedRow?: any;
  setSelectedRow?: (row: any) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  key,
  title,
  columns,
  data,
  searchPlaceholder,
  itemsPerPage,
  onRowClick,
  selectableRows,
  selectedRow,
  setSelectedRow = () => {},
}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchValue, setSearchValue] = useState("");

  // Update the view if we receive updated data
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  createTableTheme(config.theme2.isDark);

  const modifiedColumns = columns.map((column) => ({
    ...column,
    cell: (row: any) => (
      <div style={{ fontSize: "1.05rem" /* Adjust the font size as desired */ }}>
        {column.cell ? column.cell(row) : row[column.selector]}
      </div>
    ),
  }));

  const rowsPerPageOptions= [5,10,15,20];

  const handleSearch = (value: string) => {
    setSearchValue(value);

    if (!value) {
      setFilteredData(data);
      return;
    }
    
    const filtered = data.filter((item: any) =>
      Object.values(item).some(
        (val) => val && val.toString().toLowerCase().includes(value.toLowerCase())
      )
    );

    setFilteredData(filtered);
  };

  const handleRowClick = (row: any) => {
    console.log("hiiiii")
    if (onRowClick) {
      if (row.uuid === selectedRow.uuid) {
        setSelectedRow(null);
      } else {
        setSelectedRow(row);
      }
    }
    if (selectedRow && row.uuid === selectedRow.uuid) {
      setSelectedRow(null); // Unselect the row if the same row is clicked
    } else {
      setSelectedRow(row);
    }
  };

  const handleSelectedRowsChange = (state: any) => {
    if (setSelectedRow) {
      setSelectedRow(state.selectedRows[0] || null);
    }
  };

  return (
    <>
        {title ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            <h3>{title || ""}</h3>
            <span><SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder={searchPlaceholder}
            /></span>
            </div>
        ) : (
          
            <SearchInput
              value={searchValue}
              onChange={handleSearch}
              placeholder={searchPlaceholder}
            />
   
        )}
    
    {selectableRows?(
      <div style={{paddingTop:"20px"}}>
      <DataTable
      key={key}
      noDataComponent={<div style={{minWidth:'100%'}}><EmptySearchResult>No workshops found...</EmptySearchResult></div>}
      columns={modifiedColumns}
      selectableRows={selectableRows}
      selectableRowsSingle={selectableRows}
      data={filteredData}
      pagination
      paginationPerPage={itemsPerPage}
      paginationRowsPerPageOptions={rowsPerPageOptions}
      theme="grafana-dark"
      highlightOnHover
      pointerOnHover
      persistTableHead
      fixedHeader={true}
      onRowClicked={handleRowClick}
            onSelectedRowsChange={handleSelectedRowsChange} // Add this prop
            selectableRowsComponentProps={{ color: "primary" }}
            selectableRowSelected={(row) => row === selectedRow}
            sortIcon={<Icon name="arrow-up"/>}
        defaultSortFieldId={2}
        selectableRowsHighlight={true}
        paginationComponent={CustomPagination}
    />
  </div>
    ):(
      <div style={{paddingTop:"20px"}}>
      <DataTable
        key={key}
        noDataComponent={<div style={{minWidth:'100%'}}><EmptySearchResult>No workshops found...</EmptySearchResult></div>}
        columns={modifiedColumns}
        data={filteredData}
        pagination
        paginationPerPage={itemsPerPage}
        theme="grafana-dark"
        highlightOnHover
        pointerOnHover
        persistTableHead
        fixedHeader={true}
        onRowClicked={onRowClick}
        sortIcon={<Icon name="arrow-up"/>}
        defaultSortFieldId={2}
        selectableRowsHighlight={true}
        paginationComponent={CustomPagination}
      />
    </div>
    )}
    </>
  );
};

CustomTable.defaultProps = {
  selectableRows: false,
};

export default CustomTable;
