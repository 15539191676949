import React from 'react';
import { css } from '@emotion/css';
import { Alert, VerticalGroup, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

interface WorkshopCapacityAlertProps {
  // Define props here
}

const WorkshopCapacityAlert: React.FC<WorkshopCapacityAlertProps> = (props) => {
  const styles = useStyles2(getStyles);
  // Implement component logic here

  return (
    <div>
      {
        <Alert severity="warning" title="Workshop capacity reached" buttonContent="henlo">
          <VerticalGroup>
            <div>
              Sorry, we&apos;re full for workshops! You will not be able to create a new workshop, as our capacity has been
              exhausted. Reach out to <code>#field-engineering</code> in Slack for help. If you have provisioned an
              workshop which you are no longer using, please consider deleting it to free up capacity for others.{' '}
            </div>
            <div>
              For workshop events, you should schedule your workshop with Regional Marketing at least 1 month in advance.{' '}
              <a
                href="https://grafana-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145f000001f7m7AAA/page/a125f000003fNhQAAU"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              >
                For a complete guide to running a workshop, read this page on Braintank
              </a>
            </div>
          </VerticalGroup>
        </Alert>
      }
    </div>
  );
};

export default WorkshopCapacityAlert;

const getStyles = (theme: GrafanaTheme2) => ({
  link: css`
    text-decoration: underline;
  `,
});
