// CustomRadioButton.tsx
import React from 'react';

interface CustomRadioButtonProps {
  options: Array<{ label: string; value: string }>;
  value: string;
  onChange: (value: string) => void;
}

const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({ options, value, onChange }) => {
  const radioContainerStyle: React.CSSProperties = {
    display: 'flex',
    width: '100%',
  };

  const radioOptionStyle: React.CSSProperties = {
    flex: 1,
    textAlign: 'center',
    fontSize: '1.15rem',
    padding: '8px 0',
    cursor: 'pointer',
    borderBottom: '2px solid transparent',
    width: '100%',
  };

  const activeOptionStyle: React.CSSProperties = {
    borderBottom: '2px solid #6E9FFF',
  };

  return (
    <div style={radioContainerStyle}>
      {options.map((option, index) => (
        <div
          key={index}
          style={{
            ...radioOptionStyle,
            ...(option.value === value ? activeOptionStyle : {}),
          }}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default CustomRadioButton;
