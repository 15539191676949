// src/api.ts
import { getBackendSrv } from '@grafana/runtime';
import { User } from 'components/UsersList/types';

// We don't pass any user/login here, because it's already sent by the backend
export const getUserPermissions = async (): Promise<any> => {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/user/permissions`);
    return response;
  } catch (error) {
    const errorMessage = (error as { message: string }).message;
    throw new Error(`Failed to get user permissions. Error message: ${errorMessage}`);
  }
};

export async function fetchUsers(): Promise<User[]> {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.get(`api/plugins/fieldeng-brokkr-app/resources/users`);
    return response as User[];
  } catch (error) {
    console.error("There was a problem fetching the users:", error);
    throw error;
  }
}

export async function updateUser(user: User) {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.post(`api/plugins/fieldeng-brokkr-app/resources/users`, user);
    return response;
  } catch(error) {
    console.error("There was a problem updating the user:", error);
    throw error;
  }

}

export async function deleteUser(login: string) {
  try {
    const backendSrv = getBackendSrv();
    const response = await backendSrv.delete(`api/plugins/fieldeng-brokkr-app/resources/users/${login}`);
    return response;
  } catch(error) {
    console.error("There was a problem deleting the user:", error);
    throw error;
  }
}
