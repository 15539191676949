import { config } from '@grafana/runtime';
import Cookies from 'js-cookie';
import { AuthenticatedUser, User, UserRole } from 'types/userAuth';


export const getUserCredentials = (): string | null => {
  try {
    //list of fe emails to compare current users email against 
    const feEmails = ['maggie.krummel@grafana.com', 'jane@example.com', 'tom.donohue@grafana.com'];

    //read in current users email
    const usersEmail = config.bootData.user.email;

    let userRole: UserRole;
    const existingApiKey = Cookies.get('feAppApiKey');
    //if email is in list of emails then set to superadmin
    if (feEmails.includes(usersEmail)) {
      userRole = UserRole.Admin;
    } 
    else if (existingApiKey!==''){
      userRole = UserRole.SE;
    }
    
    else {
      userRole = UserRole.General;
    }

    const currentUser: User = {
      emailAddress: usersEmail,
      type: userRole
    };

    return currentUser.type;

  } catch (error) {
    console.error(error);
    return null;
  }
};

export const hasPermission = (user: AuthenticatedUser, permission: string): boolean => {
  return user.permissions ? user.permissions.includes(permission) : false;
}
