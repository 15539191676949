import React, { useState } from 'react';
import { Participant, Workshop } from 'types/workshop';
import CustomRadioButton from 'components/RadioButton/RadioButton';
import CustomTable from 'components/CustomTable/CustomTable';
import { columns } from 'components/CustomTable/columnDefinitions/workshopParticipants';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import {
  Button,
  VerticalGroup,
  Icon,
  Tooltip
} from '@grafana/ui';
import UserInfo from 'components/UserInfo/UserInfo';

type Props = {
  workshop: Workshop;
};

const WorkshopAttendeeDetails: React.FC<Props> = ({ workshop }) => {
  const [toggleValue, setToggleValue] = useState('external');
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const appEvents = getAppEvents();
  let seAttendees = [workshop.leadSEAttendee, ...workshop.breakoutSEAttendees ?? []].filter((attendee): attendee is Participant => !!attendee);

  const emailWorkshopDetails = (row: any) => {
    const body = `Hi ${row.name ? row.name : 'User'},
      \n\nThank you for joining the workshop today. You will find your login details below:
      \n\nInstance URL: https://${row.username}.grafana.net
      \n\nUsername: ${row.username}
      \n\nPassword: ${row.password}
      \n\n\n\nThank you,
      \n\n`;

    const email = row.email;
    const subject = 'Grafana Labs Workshop Details';

    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  const copyToClipboard = async (input: any, type: string) => {
    let text = '';

    if (type === 'text') {
      text = input;
    } else if (type === 'workshopObj') {
      text = `Email: ${input.email}
        Grafana Instance: https://${input.username}.grafana.net
        Username: ${input.username}
        Password: ${input.password}`;
    }

    try {
      await navigator.clipboard.writeText(text);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Copied to clipboard'],
      });
    } catch (err) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: ['Failed to copy text to clipboard.'],
      })
    }
  };

  return (
    <div style={{ display: 'flex' }}>
    <div
      style={{
        padding: '1rem',
        width: '60%',
        borderRadius: '5px',
        marginRight: '20px',
      }}
    >
      <div style={{ paddingBottom: '30px', width: '100%' }}>
        <CustomRadioButton
          options={[
            { label: 'Attendees', value: 'external' },
            { label: 'Facilitators', value: 'se' },
          ]}
          value={toggleValue}
          onChange={(value) => setToggleValue(value)}
        />
      </div>

      {toggleValue === 'external' && (
        <div>
          <CustomTable
            key={`attendees-${workshop?.workshopAttendees?.length}`}
            data={workshop?.workshopAttendees || []}
            columns={columns}
            searchPlaceholder="Search Attendees..."
            selectableRows={true}
            selectedRow={selectedRow}
            itemsPerPage={5}
            setSelectedRow={setSelectedRow}
          />
        </div>
      )}
      {toggleValue === 'se' && (
        <div>
          <CustomTable
            key={`SEs-${seAttendees.length || []}`}
            data={seAttendees || []}
            columns={columns}
            searchPlaceholder="Search SEs..."
            selectableRows={true}
            selectedRow={selectedRow}
            itemsPerPage={5}
            setSelectedRow={setSelectedRow}
          />
        </div>
      )}
      <div>
        <Button
          variant="secondary"
          icon="clipboard-alt"
          onClick={() =>
            copyToClipboard(
              workshop.workshopAttendees
                ?.concat(seAttendees || [])
                .map((item) => `${item.email} | ${item.name} | ${item.password}`)
                .join('\n'),
              'text'
            )
          }
        >
          Copy all logins to Clipboard
        </Button>
      </div>
    </div>
    <div
      style={{
        padding: '1rem',
        width: '40%',
        borderRadius: '5px',
      }}
    >
      {workshop.state?.toLowerCase() === 'provisioned' ? (
        selectedRow ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--lg-spacing)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 'var(--lg-spacing)' }}>
              <Tooltip content="Send user access details in an email" placement="top">
                <Button
                  variant="secondary"
                  icon="envelope"
                  onClick={() => emailWorkshopDetails(selectedRow)}
                >
                  Open Email Template
                </Button>
              </Tooltip>

              <div style={{ display: 'flex', gap: 'var(--lg-spacing)' }}>
                <Tooltip content="Copy user access details to clipboard..." placement="top">
                  <Button
                    variant="secondary"
                    icon="clipboard-alt"
                    onClick={() => copyToClipboard(selectedRow, 'workshopObj')}
                  >
                    Copy to Clipboard
                  </Button>
                </Tooltip>
              </div>
            </div>

            <VerticalGroup spacing="md">
              <div />
              <div />
            </VerticalGroup>
            <UserInfo participant={selectedRow} />
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <h5>
              <Icon name="arrow-left" size="xl" /> Select a user in table to access their login details...
            </h5>
          </div>
        )
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <h5>Workshop has not yet been provisioned... Please check back later for user details...</h5>
        </div>
      )}
    </div>
  </div>
  );
};

export default WorkshopAttendeeDetails;
