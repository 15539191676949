import React, { useState, ChangeEvent } from 'react';
import { Button, Field, Input, FieldSet, useStyles2, SecretInput } from '@grafana/ui';
import { PluginConfigPageProps, AppPluginMeta, PluginMeta, GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { css } from '@emotion/css';
import { lastValueFrom } from 'rxjs';
// import { Input } from 'semantic-ui-react';

export type JsonData = {
  apiUrl?: string;
  apiUsername?: string;
  isApiPasswordSet?: boolean;
};

type State = {
  // The URL to reach our custom API.
  apiUrl: string;
  // The username to use for basic authentication.
  apiUsername: string;
  // Whether the API password is set or not.
  isApiPasswordSet: boolean;
  // Password for our custom API.
  apiPassword?: string;
};

export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<JsonData>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const s = useStyles2(getStyles);
  const { enabled, pinned, jsonData } = plugin.meta;
  const [state, setState] = useState<State>({
    apiUrl: jsonData?.apiUrl || '',
    apiUsername: jsonData?.apiUsername || '',
    isApiPasswordSet: Boolean(jsonData?.isApiPasswordSet),
  });

  const onChangeApiUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      apiUrl: event.target.value.trim(),
    });
  };

  const onChangeApiUsername = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      apiUsername: event.target.value.trim(),
    });
  };

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      apiPassword: event.target.value.trim(),
    });
  };

  const onResetPassword = () => {
    setState({
      ...state,
      apiPassword: '',
      isApiPasswordSet: false,
    });
  };

  return (
    <div className="gf-form-group">
      {/* Enable the plugin */}
      <FieldSet label="Enable / Disable">
        {!enabled && (
          <>
            <div className={s.colorWeak}>The plugin is currently not enabled.</div>
            <Button
              className={s.marginTop}
              variant="primary"
              onClick={() =>
                updatePluginAndReload(plugin.meta.id, {
                  enabled: true,
                  pinned: true,
                  jsonData,
                })
              }
            >
              Enable plugin
            </Button>
          </>
        )}

        {/* Disable the plugin */}
        {enabled && (
          <>
            <div className={s.colorWeak}>The plugin is currently enabled.</div>
            <Button
              className={s.marginTop}
              variant="destructive"
              onClick={() =>
                updatePluginAndReload(plugin.meta.id, {
                  enabled: false,
                  pinned: false,
                  jsonData,
                })
              }
            >
              Disable plugin
            </Button>
          </>
        )}
      </FieldSet>

      <FieldSet label="Workshop API connection settings" className={s.marginTop}>
        {/* API Url */}
        <Field label="URL" required>
          <Input
            width={60}
            id="api-url"
            data-testid="data-testid ac-api-key"
            label={`Workshop API URL`}
            value={state.apiUrl}
            placeholder={`e.g.: https://qadwa.work-shop.grafana.net`}
            onChange={onChangeApiUrl}
          />
        </Field>

        {/* API Key */}
        <Field label="Username">
          <Input
            width={60}
            id="api-username"
            data-testid="data-testid ac-api-username"
            label={`Workshop API Username`}
            value={state.apiUsername}
            placeholder={``}
            onChange={onChangeApiUsername}
          />
        </Field>

        <Field label="Password">
          <>
            <SecretInput
              width={60}
              id="api-password"
              data-testid="ac-api-password"
              value={state.apiPassword}
              placeholder={'Paste the password'}
              onChange={onChangePassword}
              isConfigured={state.isApiPasswordSet}
              onReset={onResetPassword}
            />
          </>
        </Field>


        <div className={s.marginTop}>
          <Button
            type="submit"
            onClick={() =>
              updatePluginAndReload(plugin.meta.id, {
                enabled,
                pinned,
                jsonData: {
                  apiUrl: state.apiUrl,
                  apiUsername: state.apiUsername,
                  isApiPasswordSet: true,
                },
                secureJsonData: state.isApiPasswordSet 
                  ? undefined 
                  : { 
                      apiPassword: state.apiPassword 
                    },
              })
            }
            disabled={Boolean(!state.apiUrl || (!state.isApiPasswordSet && !state.apiPassword))}
          >
            Save API settings
          </Button>
        </div>
      </FieldSet>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  colorWeak: css`
    color: ${theme.colors.text.secondary};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
});

const updatePluginAndReload = async (pluginId: string, data: Partial<PluginMeta<JsonData>>) => {
  try {
    await updatePlugin(pluginId, data);

    // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
    // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
    window.location.reload();
  } catch (e) {
    console.error('Error while updating the plugin', e);
  }
};

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = await getBackendSrv().fetch({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  });
  return lastValueFrom(response);
};
