import React from 'react';
import { AppRootProps } from '@grafana/data';
import { PluginPropsContext } from 'utils/plugin.util';
import { AuthenticatedUserContext } from 'utils/usercontext.util';
import Routes from 'routes';
import { getUserPermissions } from 'services/userAuthAPI';

export class App extends React.PureComponent<AppRootProps> {
  state = { 
    user: {
      permissions: []
    }
  };

  componentDidMount() {
    getUserPermissions().then(permissions => {
      this.setState({ user: { permissions: permissions.permissions } });
    });
  }

  render() {
    return (
      <PluginPropsContext.Provider value={this.props}>
        <AuthenticatedUserContext.Provider value={this.state.user}>
          <Routes/>
        </AuthenticatedUserContext.Provider>
      </PluginPropsContext.Provider>
    );
  }
}
