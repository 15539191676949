import React from 'react';
import { PluginPage } from './partials/PluginPage';
import { config } from '@grafana/runtime';
import { Icon, LinkButton, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { ROUTES, prefixRoute } from 'utils/routing.util';

const Home = () => {
  const styles = useStyles2(getStyles);
  console.log(config.bootData.user);

  return (
    <PluginPage>
      <section className={styles.section}>
        <div className={styles.subsection}>
          <h3>What is Brokkr?</h3>
          <ul className={styles.list}>
            <li>
              Brokkr is an internal Grafana Labs app for creating and managing temporary Grafana Cloud environments,
              used for workshops, demos and other events.
            </li>
            <li>
              Anyone with access to this Grafana instance can view existing environment details.
            </li>
            <li>
              If you want to use this service to create an environment for a workshop, please first schedule your workshop with
              the Marketing team, and then reach out on
              <Icon size="sm" name="slack" /> #field-engineering for edit access.
            </li>
            <li>
              For more information,{' '}
              <a
                className={'external-link'}
                target="_blank"
                href="https://grafana-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145f000001f7m7AAA/page/a125f000003ezoLAAQ"
                rel="noreferrer"
              >
                <span style={{ whiteSpace: 'nowrap' }}>
                  see this page on Braintank <Icon size="sm" name="external-link-alt" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.subsection}>
          <h3>Get started</h3>
          <p>Go to the Environments list, to view, manage and create environments:</p>
          <LinkButton href={prefixRoute(ROUTES.Environments)}>Go to Environments</LinkButton>
        </div>
      </section>
    </PluginPage>
  );
};

export default Home;

const getStyles = (theme: GrafanaTheme2) => ({
  section: css({
    display: 'flex',
    columnGap: theme.spacing(4),
  }),
  subsection: css({
    flex: 1,
    backgroundColor: theme.colors.background.secondary,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(4),
  }),
  list: css`
    margin: ${theme.spacing(0, 2)};
    & > li {
      margin-bottom: ${theme.spacing(1)};
    }
  `,
});
