import useColumns from './useColumns';
import { User } from './types';
import { Button, FilterInput, InteractiveTable, Stack } from '@grafana/ui';
import React from 'react';

interface UsersListProps {
  query: string;
  data: User[];
  disabled: boolean;
  changeQuery: (query: string) => void;
  updateUser: (user: User) => Promise<void>; // These are passed down as references. The definition is given in the parent component.
  deleteUser: (login: string) => Promise<void>;
  refreshData: () => void;
}

export const UsersList = ({ changeQuery, disabled, query, data, updateUser, deleteUser, refreshData }: UsersListProps) => {
  const columns = useColumns({ updateUser, deleteUser, refreshData, disabled });

  // Only enable the 'Add user' button if it's a valid @grafana.com email address
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@grafana\.com$/;
    return emailRegex.test(email);
  };
  const isValid: boolean = isValidEmail(query);

  return (
    <div style={{ maxWidth: 800 }}>
      <Stack grow={1} direction={'column'} gap={2}>
        <Stack gap={2}>
          <FilterInput
            placeholder="Enter an email address to find or add, e.g. user@grafana.com"
            autoFocus={true}
            value={query}
            onChange={changeQuery}
            escapeRegex={false}
          />
          <Button
            variant="primary"
            disabled={disabled || !isValid}
            icon="plus"
            onClick={() => {
              updateUser({ login: query, group: 'facilitators' })
                .then(() => {
                  refreshData();
                  changeQuery('');
                })
                .catch((error) => {
                  console.error('Failed to add user:', error);
                });
            }}
          >
            Add user
          </Button>
        </Stack>
        <InteractiveTable columns={columns} data={data} pageSize={10} getRowId={(user: User) => String(user.login)} />
      </Stack>
    </div>
  );
};
