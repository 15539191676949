const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const grafanaEmailRegex = /^[a-zA-Z0-9._%-]+[^+]@grafana\.com$/;

export const validateField = (name: string, value: any): string => {
  let errorMessage = '';
  switch (name) {
    case 'name':
      if (!value) {
        errorMessage = 'Workshop name is required.';
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        errorMessage = 'Workshop name must be alphanumeric.';
      } else if (value.length < 6 || value.length > 12) {
        errorMessage = 'Must be >= 6 and >= 12 characters.';
      }
      break;

    case 'type':
      if (!value) {
        errorMessage = 'Workshop type must be selected.';
      }
      break;

    case 'scheduledDate':
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const maxDate = new Date();
      maxDate.setDate(today.getDate() + 7);

      if (value < today || value > maxDate) {
        errorMessage = 'Event date must be within the next 7 days.';
      }
      break;

    case 'breakoutSEs':
      const nonStaffEmails = value.filter((item: any) => !grafanaEmailRegex.test(item.value));
      if (nonStaffEmails.length > 0) {
        errorMessage = 'Each facilitator must be a valid grafana.com email address';
      }
      break;

    case 'leadSE':
      if (!grafanaEmailRegex.test(value)) {
        errorMessage = 'This must be a nondynamic Grafana email';
      }
      break;

  }

  return errorMessage;
};

export const validateEmailAddressesFile = async (file: File): Promise<string[]> => {
  return new Promise<string[]>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const content = e.target?.result;
      if (typeof content !== 'string') {
        reject(new Error('Invalid file content'));
        return;
      }

      const emails = content
        .split(/[\n,]/) // Split by newline or comma
        .map((email) => email.trim().toLowerCase())
        .filter((email) => email.length > 0); // Filter out empty strings

      if (emails.every((email) => emailRegex.test(email))) {
        resolve(emails);
      } else {
        reject(new Error('The file contains invalid email addresses.'));
      }
    };

    reader.onerror = () => {
      reject(new Error('Error reading the file.'));
    };

    reader.readAsText(file);
  });
};
