import React, { useState } from 'react';
import { Button, HorizontalGroup} from '@grafana/ui';
import CopyButton from 'components/CustomTable/subTableComponents/CopyButton';

interface UserInfoProps {
  password: string | undefined;
}

const ToggleablePasswordLabel: React.FC<UserInfoProps> = ({ password }) => {
    const [isPasswordVisible, setVisiblePasswordId] = useState(false);

    function onToggleVisibility(e: any) {
        e.stopPropagation();
        if(isPasswordVisible === false ){
            setVisiblePasswordId(true)
        }
        else {
            setVisiblePasswordId(false)
        }
    }

    return (
      <HorizontalGroup>
        <h5>{isPasswordVisible ? password : '********'}
          <Button
              size="sm"
              fill='text'
              variant={isPasswordVisible ? 'primary' : 'primary'}
              onClick={(e) => onToggleVisibility(e)}
              style={{ marginLeft: 8 }}
          >
              {isPasswordVisible ? 'Hide' : 'Show'}
          </Button>
          {isPasswordVisible && <CopyButton text={password} />}</h5>
        </HorizontalGroup>
      );
    }
    
    export default ToggleablePasswordLabel;
