export interface ScheduleWorkshopObj {
  name?: string;
  description: string;
  type: string;
  scheduledDate?: string;
  leadSE?: string;
  breakoutSEs?: string[];
  attendees?: string[];
  [index: string]: any;
  grafanaCloudRegion: string;
  config: any;
}



export const grafanaCloudRegions = [
  {
    label: 'Grafana Cloud - AWS US West',
    value: 'prod-us-west-0',
    description: 'prod-us-west-0',
  }
];
