import { PluginPage } from '@grafana/runtime';
import { LinkButton } from '@grafana/ui';
import ScheduleWorkshopForm from 'components/ScheduleWorkshopForm/ScheduleWorkshopForm';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Workshop } from 'types/workshop';
import { ROUTES, prefixRoute } from 'utils/routing.util';

export const ScheduleWorkshop = () => {
  const history = useHistory();

  const handleWorkshopCreated = (workshop: Workshop) => {
    history.push(`${prefixRoute(ROUTES.Environments)}`);
  };

  return (
    <PluginPage
      pageNav={{
        text: 'New environment',
        subTitle: 'Create a new temporary Grafana Cloud environment for a customer workshop',
      }}
      actions={
        <>
          <LinkButton href={'https://grafana-intranet--simpplr.vf.force.com/apex/simpplr__app?u=/site/a145f000001f7m7AAA/page/a125f000003ezoLAAQ'}
            variant="secondary"
            size="sm"
            icon='question-circle'
            target='_blank'
          >Help/Documentation</LinkButton>
        </>
      }
    >
      <ScheduleWorkshopForm onWorkshopCreated={handleWorkshopCreated} />
    </PluginPage>
  );
};

export default ScheduleWorkshop;
