import React, {  } from 'react';
import {  HorizontalGroup, VerticalGroup, Card } from '@grafana/ui';
import { Participant } from 'types/workshop';
import CopyButton from 'components/CustomTable/subTableComponents/CopyButton';
import ToggleablePasswordLabel from 'components/ToggleablePasswordLabel/ToggleablePasswordLabel';

interface UserInfoProps {
  participant: Participant;
}

const UserInfo: React.FC<UserInfoProps> = ({ participant }) => {
    const { name, email, username } = participant;
    const grafanaInstance= `https://${participant.username}.grafana.net`

    return (
      <VerticalGroup spacing="md">
          <Card>
            <img
              src={`https://robohash.org/${username}`}
              alt={`${username} avatar`}
              style={{ width: "60px", height: "60px", borderRadius: "50%", marginRight: "10px", border: "2px solid white" }}
            />
            <div>
              <h4>{name !== undefined && name !== null ? name : "Unknown"}</h4>
              <h5>{email} <CopyButton text={email} /></h5>
            </div>
          </Card>

          <HorizontalGroup>
            <h5>Grafana Instance:</h5>
            <h5><a href={grafanaInstance} target="_blank" rel="noopener noreferrer"><u>{grafanaInstance}</u></a></h5>
            <CopyButton text={grafanaInstance} />
            </HorizontalGroup>

            <HorizontalGroup>
            <h5 >Username:</h5>
            <h5>{username}</h5>
            <CopyButton text={username} />
          </HorizontalGroup>

          <HorizontalGroup>
            <h5 >Password:</h5>
            <ToggleablePasswordLabel password={participant.password} />
          </HorizontalGroup>
        </VerticalGroup>
    );
  }
    
  export default UserInfo;
