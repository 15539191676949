import { createTheme } from 'react-data-table-component';

export const createTableTheme = (isDark: boolean) => {
  if (isDark) {
    createTheme('grafana-dark', {
      text: {
        primary: 'rgb(204, 204, 220)',
        secondary: 'rgb(204,204,220, .50)',
      },
      background: {
        default: '#181b1f;',
      },
      defaultColumn:{
        width:'auto'
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: 'rgba(204, 204, 220, 0.07)',
      },
      highlightOnHover: {
        default: 'rgb(204,204,220,.2)',
        text: '#FFFFFF',
      },
      selected: {
        default: 'rgb(204,204,220,.2)',
        text: '#FFFFFF',
      },
      sortFocus: {
        default: '#2aa198',
      },
      button: {
        default: 'rgba(0,0,0,.54)',
        focus: 'rgba(0,0,0,.12)',
        hover: 'rgba(0,0,0,.12)',
        disabled: 'rgba(0, 0, 0, .18)',
      },
    });
  } else {
    createTheme('grafana-light', {
      text: {
        primary: 'rgb(36, 41, 46);',
        secondary: '#2aa198',
      },
      background: {
        default: 'rgb(255, 255, 255);',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: 'rgba(36, 41, 46, 0.12)',
      },
      highlightOnHover: {
        default: 'rgb(247, 247, 247)',
      },
      sortFocus: {
        default: '#2aa198',
      },
    });
  }
};
