export interface Capacity {
    maximum: number;
    used: number;
    remaining: number;
}

export interface Status {
    workshopCapacity: Capacity;
    isFullWorkshops(): boolean;

    deployableDemoCapacity: Capacity;
    isFullDeployableDemos(): boolean;
}

export class StatusImpl implements Status {
    workshopCapacity: Capacity;
    deployableDemoCapacity: Capacity;

    constructor(response: any) {
        this.workshopCapacity = response.workshop.capacity;
        this.deployableDemoCapacity = response.deployabledemo.capacity
    }

    isFullWorkshops = () => {
        return this.workshopCapacity.remaining === 0;
    }

    isFullDeployableDemos = () => {
        return this.deployableDemoCapacity.remaining === 0;
    }
}
