import { IconButton, HorizontalGroup } from "@grafana/ui";
import React from "react";

export const CustomPagination: React.FC<any> = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
    const goToPreviousPage = () => {
      if (currentPage > 1) {
        onChangePage(currentPage - 1);
      }
    };
  
    const goToNextPage = () => {
      if (currentPage < Math.ceil(rowCount / rowsPerPage)) {
        onChangePage(currentPage + 1);
      }
    };
  
    return (
      <HorizontalGroup align="flex-end" justify="flex-end">
      <div className="custom-pagination">
        <IconButton name="arrow-left" variant="secondary" aria-label="Previous page" size='lg' onClick={goToPreviousPage} disabled={currentPage === 1}>
        </IconButton>
        <span className="custom-pagination-text">
          Page {currentPage} of {Math.ceil(rowCount / rowsPerPage)}
        </span>
        <IconButton name="arrow-right" variant="secondary" aria-label="Next page" size='lg' onClick={goToNextPage} disabled={currentPage === Math.ceil(rowCount / rowsPerPage)}>
        </IconButton>
      </div>
      </HorizontalGroup>
    );
};
