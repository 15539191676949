import React, { useMemo } from 'react';
import { CellProps, Column, ConfirmModal, IconButton, Select } from '@grafana/ui';
import { User } from './types';

type Cell<T extends keyof User = keyof User> = CellProps<User, User[T]>;

interface UseColumnsProps {
  updateUser: (user: User) => Promise<void>;
  deleteUser: (login: string) => Promise<void>;
  refreshData: () => void;
  disabled: boolean;
}

// Separate component for the delete button
const DeleteButton: React.FC<{
  login: string;
  disabled: boolean;
  onDelete: (login: string) => Promise<void>;
  refreshData: () => void;
}> = ({ login, disabled, onDelete, refreshData }) => {
  const [showModal, setShowModal] = React.useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <IconButton
        name={'trash-alt'}
        aria-label={`Delete user ${login}`}
        disabled={disabled}
        onClick={() => setShowModal(true)}
      />

      <ConfirmModal
        isOpen={showModal}
        body={`Are you sure you want to delete user ${login}? This will not remove any workshops associated with the user.`}
        confirmText="Delete"
        title="Delete user"
        onConfirm={() => {
          onDelete(login)
            .then(() => refreshData())
            .catch((error) => {
              console.error('Failed to delete user:', error);
            });
          closeModal();
        }}
        onDismiss={closeModal}
      />
    </>
  );
};

const useColumns = ({ updateUser, deleteUser, refreshData, disabled }: UseColumnsProps) => {
  console.log(`useColumns | disabled: ${disabled}`);
  const columns: Array<Column<User>> = useMemo(
    () => [
      {
        id: 'login',
        header: 'Login',
        cell: ({ cell: { value } }: Cell<'login'>) => value,
        sortType: 'string',
      },
      {
        id: 'group',
        header: 'Group',
        cell: ({ cell: { value }, row: { original } }: Cell<'group'>) => {
          return (
            <Select
              options={[
                { label: 'Facilitators', value: 'facilitators', description: 'Can create and delete environments' },
                {
                  label: 'Administrators',
                  value: 'administrators',
                  imgUrl: '/public/plugins/fieldeng-brokkr-app/assets/images/crown.svg',
                  description: 'Can perform all functions in the app.',
                },
              ]}
              value={value}
              disabled={disabled}
              onChange={(option) => {
                updateUser({ login: original.login, group: option.value })
                  .then(() => refreshData())
                  .catch((error) => {
                    console.error('Failed to update user:', error);
                  });
              }}
            />
          );
        },
        sortType: 'string',
      },
      {
        id: 'delete',
        header: '',
        cell: ({ row: { original } }: Cell) => (
          <DeleteButton
            login={original.login}
            disabled={disabled}
            onDelete={deleteUser}
            refreshData={refreshData}
          />
        ),
      },
    ],
    [updateUser, deleteUser, refreshData, disabled]
  );
  return columns;
};

export default useColumns;
