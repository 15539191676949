import React, { useState, useEffect, useContext } from 'react';
import { useInterval } from "react-use";
import { css } from '@emotion/css';
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import WorkshopDetails from 'pages/WorkshopDetails';
import { workshopColumns } from 'components/CustomTable/columnDefinitions/workshop';
import CustomTable from 'components/CustomTable/CustomTable';
import WorkshopCapacityAlert from 'components/CapacityAlert/WorkshopCapacityAlert';
import DeployableDemoCapacityAlert from 'components/CapacityAlert/DeployableDemoCapacityAlert';
import { prefixRoute, ROUTES } from 'utils/routing.util';
import { getWorkshops, getSystemStatus } from 'services/workshopAPI';
import { Capacity } from 'types/status';
import { Badge, Button, HorizontalGroup, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import { Workshop } from 'types/workshop';
import { GrafanaTheme2 } from '@grafana/data';
import { AuthenticatedUserContext } from 'utils/usercontext.util';
import { hasPermission } from 'utils/auth.util';
import { PluginPage } from '@grafana/runtime';
import ScheduleWorkshop from 'pages/ScheduleWorkshop';

function WorkshopListPage() {
  const [workshops, setWorkshops] = useState([] as Workshop[]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFullWorkshops, setIsFullWorkshops] = useState(false);
  const [isFullDeployableDemos, setIsFullDeployableDemos] = useState(false);
  const [status, setStatus] = useState({} as any);
  const authenticatedUser = useContext(AuthenticatedUserContext);
  const history = useHistory();
  const styles = useStyles2(getStyles);

  const WORKSHOP_LIST_POLL_INTERVAL_MILLIS = 60000;

  useEffect(() => {
    fetchWorkshops();
  }, []);

  const fetchWorkshops = async () => {
    Promise.all([
      getWorkshops(),
      getSystemStatus()
    ]).then(([workshops, status]) => {
      setWorkshops(workshops);
      setStatus(status);
      setIsFullWorkshops(status.isFullWorkshops());
      setIsFullDeployableDemos(status.isFullDeployableDemos());
      setIsLoading(false);
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    });
  };

  useInterval(() => {
    fetchWorkshops();
  }, WORKSHOP_LIST_POLL_INTERVAL_MILLIS);

  const handleRowClick = (row: any) => {
    history.push(`${prefixRoute(ROUTES.Environments)}/${row.uuid}`);
  };

  const ScheduleNewWorkshop = (e: any) => {
    e.preventDefault();
    history.push(`${prefixRoute(ROUTES.Environments)}/schedule`);
  };

  return (
    <PluginPage
        pageNav={{
          text: 'Environments',
          subTitle: 'Manage temporary Grafana Cloud environments for customer workshops',
          hideFromBreadcrumbs: true,
        }}
        actions={
          <HorizontalGroup spacing='md'>
            {renderSystemStatus("Workshop", status.workshopCapacity)}
            {renderSystemStatus("Application Environment", status.deployableDemoCapacity)}
            <Button icon="plus" variant="primary" onClick={ScheduleNewWorkshop} disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:create'))}>
              New environment
            </Button>
          </HorizontalGroup>
        }
    >
      <div>
        {isFullWorkshops && (
          <WorkshopCapacityAlert/>
        )}

        {isFullDeployableDemos && (
          <DeployableDemoCapacityAlert/>
        )}

        {isLoading ? (
          <LoadingPlaceholder text="Loading environments..." />
        ) : (
          <div className={styles.workshopList}>
            <CustomTable
              data={workshops}
              columns={workshopColumns}
              title=""
              searchPlaceholder="Search workshops..."
              onRowClick={handleRowClick}
              itemsPerPage={50}
            />
          </div>
        )}
      </div>
    </PluginPage>
  );
}

function renderSystemStatus(type: string, capacity: Capacity | undefined) {
  if (capacity?.remaining === 0) {
    return <Badge color="red" text={`No ${type} slots available`} icon='exclamation-circle' tooltip={`The ${type} service is at full capacity right now.`}/>;
  }
  if (capacity?.remaining === 1) {
    return <Badge color="orange" text={`1 ${type} slot available`} />;
  }
  if (capacity?.remaining !== undefined && capacity.remaining > 1) {
    return <Badge color="blue" text={`${capacity?.remaining} ${type} slots available`} />;
  }
  return <Badge color="orange" text={`${type} capacity unknown`} icon='exclamation-circle' />;
}

export enum WorkshopSubRoutes {
  scheduleWorkshop = 'schedule',
  viewWorkshop = ':id',
}

const Workshops = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route exact path={`${path}/${WorkshopSubRoutes.scheduleWorkshop}`} component={ScheduleWorkshop} />
        <Route exact path={`${path}/${WorkshopSubRoutes.viewWorkshop}`} component={WorkshopDetails} />
        <Route exact path={`${path}`} component={WorkshopListPage} />
        <Route path={`${path}/`}>
          <Redirect to={`${path}`} />
        </Route>
      </Switch>
  );
};

export default Workshops;

const getStyles = (theme: GrafanaTheme2) => ({
  workshopList: css`
    padding-top: ${theme.spacing(2)};
  `,
});
