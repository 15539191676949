import React, { useState } from 'react';
import { IconButton } from '@grafana/ui';

interface CopyButtonProps {
  text?: string;
}

const copyToClipboard = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.textContent = text;
  textarea.style.position = 'absolute';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = async () => {
    if (text) {
      copyToClipboard(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <>
      <IconButton size="sm" name="copy" aria-label="Copy to clipboard" onClick={() => handleClick()} style={{ marginLeft: 8 }} />
      {copied && (
        <div style={{ display: 'inline', marginLeft: 8 }}>
          Copied!
        </div>
      )}
    </>
  );
};

export default CopyButton;
