import React from 'react';
import { InteractiveTable } from "@grafana/ui";
import useColumns from './useColumns';
import { Participant } from 'types/workshop';

interface WorkshopAttendeeDetailsProps {
  data: Participant[];
}

export const WorkshopAttendeeDetails = ({ data }: WorkshopAttendeeDetailsProps) => {
    const columns = useColumns();

    return (
      <InteractiveTable columns={columns} data={data} pageSize={100} getRowId={(participant: Participant) => String(participant.uuid)} />
    );

};

export default WorkshopAttendeeDetails;
