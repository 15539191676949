export interface User {
    uuid?: string;
    name?: string;
    type: string;
    emailAddress?: string;
    authenticationToken?: string;
    gcomToken?: string;
  }

  export enum UserRole {
  General = "general",
  Admin = "admin",
  SE = "se",
}

export interface AuthenticatedUser {
  permissions: string[];
}
