import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavModel, NavLinkDTO } from '@grafana/data';
import { config } from '@grafana/runtime';
import { usePluginProps } from './plugin.util';
import pluginJson from '../plugin.json';

export const NAVIGATION_TITLE = 'Environments';
export const NAVIGATION_SUBTITLE = 'Field Engineering Enablement App';
export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Home='home',
  Environments='environments',
  Users='users',
}

// Add a navigation item for each route you would like to display in the navigation bar
export const NAVIGATION: Record<string, NavLinkDTO> = {
  [ROUTES.Home]: {
    id: ROUTES.Home,
    text: `${pluginJson.includes[0].name}`,
    icon: 'list-ul',
    url: `${PLUGIN_BASE_URL}/${ROUTES.Home}`,
  },
  [ROUTES.Environments]: {
    id: `${ROUTES.Environments}`,
    text: `${pluginJson.includes[1].name}`,
    icon: 'database',
    url: `${PLUGIN_BASE_URL}/${ROUTES.Environments}`,
  },
  [ROUTES.Users]: {
    id: `${ROUTES.Users}`,
    text: `${pluginJson.includes[2].name}`,
    icon: 'database',
    url: `${PLUGIN_BASE_URL}/${ROUTES.Users}`,
  }
  
};

// Displays a top navigation tab-bar if needed
export function useNavigation() {
  const pluginProps = usePluginProps();
  const location = useLocation();
  useEffect(() => {
    if (!pluginProps) {
      console.error('Root plugin props are not available in the context.');
      return;
    }

    if (config.featureToggles.topnav) {
      return;
    }

    const activeId = Object.keys(NAVIGATION).find((routeId) => location.pathname.includes(routeId)) || '';
    const activeNavItem = NAVIGATION[activeId];
    const { onNavChanged, meta, basename } = pluginProps;

    // Disable tab navigation
    // (the route is not registered as a navigation item)
    if (!activeNavItem) {
      onNavChanged(undefined as unknown as NavModel);
    }

    // Show tabbed navigation with the active tab
    else {
      onNavChanged(
        getNavModel({
          activeId,
          basePath: basename,
          logoUrl: meta.info.logos.large,
        })
      );
    }
  }, [location.pathname, pluginProps]);
}

// Prefixes the route with the base URL of the plugin
export function prefixRoute(route: string): string {
  return `${PLUGIN_BASE_URL}/${route}`;
}

export function getNavModel({ activeId, basePath, logoUrl }: { activeId: string; basePath: string; logoUrl: string }) {
  const main = {
    text: NAVIGATION_TITLE,
    subTitle: NAVIGATION_SUBTITLE,
    url: basePath,
    img: logoUrl,
    children: Object.values(NAVIGATION).map((navItem) => ({
      ...navItem,
      active: navItem.id === activeId,
    })),
  };

  return {
    main,
    node: main,
  };
}
