export const isWithin48Hours = (date: Date): boolean => {
  const now = new Date();
  const hoursDifference = Math.abs(date.getTime() - now.getTime()) / 36e5;
  return hoursDifference < 48;
};

export const calculateExpiryDate = (date: Date): Date => {
  return new Date(date.getTime() + 5 * 24 * 60 * 60 * 1000);
}

export const humanExpiryDate = (expiryDate: string | undefined): String | null => {
  if (!expiryDate) {
    return null;
  }

  const expiryDateObj = new Date(expiryDate);
  const now = new Date();

  if (expiryDateObj < now) {
    return 'Pending deletion';
  }

  const diff = expiryDateObj.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  
  if (days < 1) {
    if (hours < 1) {
      const minutes = Math.floor(diff / (1000 * 60));
      return `In ${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }
    return `In ${hours} hour${hours !== 1 ? 's' : ''}`;
  } else if (days <= 3) {
    return `In ${days} day${days !== 1 ? 's' : ''}`;
  } else {
    // Format date according to user's locale
    return expiryDateObj.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }
}

export const replaceVariables = (markdown: string, outputs?: Record<string, any>): string => {
  if (!outputs) {
    return markdown;
  }
  
  return markdown.replace(/\${([^}]+)}/g, (match, varName) => {
    const value = outputs[varName];
    return value !== undefined ? String(value) : `{missing:${varName}}`;
  });
};

