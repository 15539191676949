import React, { FC } from "react";
import { Switch, Route } from "react-router-dom";
import Home from "pages/Home";
import Workshops from "pages/Workshops";
import { ROUTES, prefixRoute } from "utils/routing.util";
import Users from "pages/Users";

const Routes: FC = () => {
  // useNavigation();

  return (
    <Switch>
      <Route exact path={"/|" + prefixRoute(ROUTES.Home)} component={Home} />
      <Route
        path={prefixRoute(ROUTES.Environments)}
        component={Workshops}
      />
      <Route
        path={prefixRoute(ROUTES.Users)}
        component={Users}
        />
      <Route exact path={'*'} component={Home} />
    </Switch>

  );
};

export default Routes;
