import { Card, HorizontalGroup, Icon } from '@grafana/ui';
import { WorkshopState } from 'components/CustomTable/subTableComponents/ColorMapping';
import React from 'react';
import { Workshop } from 'types/workshop';
import { toIconName } from '@grafana/data';
import FormattedDate from 'components/CustomTable/subTableComponents/FormattedDate';

type Props = {
  workshop: Workshop;
};

const WorkshopInfo: React.FC<Props> = ({ workshop }) => {
  let cards = [
    {
      label: 'Created Date',
      icon: 'calendar-alt',
      value: workshop.scheduledDate && <FormattedDate date={workshop.scheduledDate} />,
    },
    {
      label: 'Expiry',
      icon: 'trash-alt',
      value: workshop.deprovisionDate ? <FormattedDate date={workshop.deprovisionDate} /> : 'Never',
    },
    {
      label: 'Infrastructure Status',
      icon: 'brackets-curly',
      value: <WorkshopState value={workshop.state || 'unknown'} />,
    },
    {
      label: 'Lead SE',
      icon: 'user',
      value: workshop.leadSEAttendee?.name,
    },
    {
      label: 'Attendees',
      icon: 'users-alt',
      value: workshop.workshopAttendees?.length || 0,
    },
    {
      label: 'Breakout SEs',
      icon: 'user-arrows',
      value: workshop.breakoutSEAttendees?.length || 0,
    }
  ];

  return (
    <HorizontalGroup spacing="md">
      {cards.map((item) => (
        <Card key={item.label}>
          <Card.Heading>
            <HorizontalGroup>
              <Icon name={toIconName(item.icon) ?? 'circle'} /> {item.label}
            </HorizontalGroup>
          </Card.Heading>
          <Card.Description>
            <div style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>{item.value}</div>
          </Card.Description>
        </Card>
      ))}
    </HorizontalGroup>
  );
};

export default WorkshopInfo;
