import { HorizontalGroup } from "@grafana/ui";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

interface ColorCellProps {
    value: string;
  }

export const WorkshopState: React.FC<ColorCellProps> = ({ value }) => {

let style='rgb(204,204,220)'
let percent=100;

  if (value === 'provisioned') {
    style = '#6CCF8E';
  } else if (value === 'provisioning' || value === 'pending') {
    style = '#F8D06B';
    percent=60;
  }
  else if (value === 'deleting') {
    style = '#F8D06B';
    percent=30;
  } else {
    style = '#FF5286';
    percent=22;
  }

  return (
    <>
     <HorizontalGroup align="center">
    <div style={{color:`${style}`, textTransform: 'capitalize'}}>{value}</div>
    <ProgressBar baseBgColor="rgba(204, 204, 220, 0.16)" completed={percent} bgColor={style} customLabel="*********" labelSize="17px" height="7px" labelColor={style}></ProgressBar>
  </HorizontalGroup></>)
};
