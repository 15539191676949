import { PluginPage } from '@grafana/runtime';
import { Alert, Spinner } from '@grafana/ui';
import { UsersList } from 'components/UsersList/UsersList';
import { User } from 'components/UsersList/types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import * as userAuthAPI from 'services/userAuthAPI';
import { hasPermission } from 'utils/auth.util';
import { AuthenticatedUserContext } from 'utils/usercontext.util';

const UsersPage: React.FC = () => {
  const [query, setQuery] = useState<string>('');
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const authenticatedUser = useContext(AuthenticatedUserContext);

  const loadUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedUsers = await userAuthAPI.fetchUsers();
      setUsers(fetchedUsers);
    } catch (err) {
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateUser = useCallback(async (user: User) => {
    setIsLoading(true);
    setError(null);
    try {
      console.log(`Updating user ${user.login}`);
      await userAuthAPI.updateUser({ login: user.login, group: user.group });
      // Once this function completes, a refresh will be triggered (see useColumns.tsx)
    } catch (err) {
      setError('Failed to update user. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteUser = useCallback(async (login: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await userAuthAPI.deleteUser(login);
    } catch (err) {
      setError('Failed to delete user. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleQueryChange = (newQuery: string) => {
    setQuery(newQuery);
  };

  useEffect(() => {
    if (authenticatedUser) {
      const hasEditPermission = hasPermission(authenticatedUser, 'users:edit');
      setCanEdit(hasEditPermission);
    } else {
      setCanEdit(false);
    }
  }, [authenticatedUser]);

  // Use useEffect to load users when the component mounts or when dependencies change
  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => user.login.toLowerCase().includes(query.toLowerCase()));
  }, [users, query]);

  return (
    <PluginPage
      pageNav={{
        text: 'Users',
        subTitle: 'Manage access to create and manage environments in this app',
        hideFromBreadcrumbs: true,
      }}
      actions={<>{isLoading && <Spinner></Spinner>}</>}
    >
      {error ? (
        <Alert title={error} severity="error" />
      ) : (
        <>
          {!canEdit ? (
            <Alert title={'This user list is read-only'} severity="info">
              To add or modify users, please speak to <code>#field-engineering</code>.
            </Alert>
          ) : (
            <></>
          )}
          <UsersList
            query={query}
            data={filteredUsers}
            disabled={!canEdit}
            changeQuery={handleQueryChange}
            updateUser={updateUser} // Pass down the function to call to update the user
            deleteUser={deleteUser} // Pass down the function to call to delete the user
            refreshData={loadUsers} // Pass down a callback to trigger a refresh
          />
        </>
      )}
    </PluginPage>
  );
};

const Users = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={UsersPage} />
      <Route path={`${path}/`}>
        <Redirect to={`${path}`} />
      </Route>
    </Switch>
  );
};

export default Users;
