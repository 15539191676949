import React, { useMemo } from 'react';
import { CellProps, Column, Icon, Tooltip } from '@grafana/ui';
import ToggleablePasswordLabel from 'components/ToggleablePasswordLabel/ToggleablePasswordLabel';
import { Participant } from 'types/workshop';

type Cell<T extends keyof Participant = keyof Participant> = CellProps<Participant, Participant[T]>;

const useColumns = () => {
  const columns: Array<Column<Participant>> = useMemo(
    () => [
      {
        id: 'type',
        header: '',
        cell: ({ row }: Cell) =>
          row.original.email?.endsWith('@grafana.com') ? (
            <Tooltip content={'User is Grafanista'}>
              <Icon name="grafana" />
            </Tooltip>
          ) : null,
      },
      {
        id: 'email',
        header: 'Email',
        cell: ({ cell: { value } }: Cell<'email'>) => value,
        sortType: 'string',
      },
      {
        id: 'username',
        header: 'Username',
        cell: ({ cell: { value } }: Cell<'username'>) => value,
        sortType: 'string',
      },
      {
        id: 'password',
        header: 'Password',
        cell: ({ cell: { value } }: Cell<'password'>) => {
          return <ToggleablePasswordLabel password={value} />;
        },
        sortType: 'string',
      },
      {
        id: 'stack_url',
        header: 'Stack URL',
        cell: ({ cell: { value } }: Cell<'stack_url'>) => value,
        sortType: 'string',
      },
    ],
    []
  );
  return columns;
};

export default useColumns;
