import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Card,
  Button,
  Select,
  Input,
  Field,
  DatePickerWithInput,
  Tooltip,
  Icon,
  HorizontalGroup,
  VerticalGroup,
  IconButton,
  Spinner,
  Modal,
  Alert,
  CodeEditor,
  FieldSet,
  FieldValidationMessage,
  AsyncMultiSelect,
} from '@grafana/ui';
import { Workshop } from 'types/workshop';
import ProgressBar from "@ramonak/react-progress-bar";
import WorkshopCapacityAlert from 'components/CapacityAlert/WorkshopCapacityAlert';
import DeployableDemoCapacityAlert from 'components/CapacityAlert/DeployableDemoCapacityAlert';
import { createWorkshop, getSystemStatus } from 'services/workshopAPI';
import { prefixRoute, ROUTES } from 'utils/routing.util';
import { ScheduleWorkshopObj, workshopTypes, grafanaCloudRegions, WorkshopType } from 'components/ScheduleWorkshopForm/types';
import { validateField, validateEmailAddressesFile } from 'components/ScheduleWorkshopForm/validate';
import DateObject from 'react-date-object';
import { useHistory } from 'react-router-dom';
import { config, getAppEvents, getBackendSrv } from '@grafana/runtime';
import { AppEvents, SelectableValue } from '@grafana/data';
import { AuthenticatedUserContext } from 'utils/usercontext.util';
import { hasPermission } from 'utils/auth.util';
import { calculateExpiryDate } from 'utils/workshop.util';
import debounce from 'debounce-promise';

interface ScheduleWorkshopFormProps {
  onWorkshopCreated: (workshop: Workshop) => void;
}

const ScheduleWorkshopForm: React.FC<ScheduleWorkshopFormProps> = ({ onWorkshopCreated }) => {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 3);
  const history = useHistory();
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const name = config.bootData.user.email ? config.bootData.user.email : 'unknown';
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const inputElement = useRef<HTMLInputElement>(null);
  // Add a new state variable for tracking the loading state
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFullWorkshops, setIsFullWorkshops] = useState(false);
  const [isFullDeployableDemos, setIsFullDeployableDemos] = useState(false);
  const [isDeployableDemo, setIsDeployableDemo] = useState(false);
  const [customConfig, setCustomConfig] = useState('{}');
  const [isShowCustomConfig, setIsShowCustomConfig] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<WorkshopType | null>(null);
  const [facilitators, setFacilitators] = useState<Array<SelectableValue<string>>>([]);
  const authenticatedUser = useContext(AuthenticatedUserContext);
  const appEvents = getAppEvents();

  // Use the Grafana API to pre-populate a list of users for the facilitators dropdown
  const loadAsyncOptions = useCallback((inputValue: string) => {
    return new Promise<Array<SelectableValue<string>>>(async (resolve) => {
      if (inputValue.length < 3) {
        resolve([]);
      }
      try {
        const response = await getBackendSrv().get(`api/org/users/search`,
          { perpage: 30, page: 0, query: inputValue, accesscontrol: true }, '', { showErrorAlert: false });
        const result = response.orgUsers.map((item: { email: string }) => ({
          label: item.email,
          value: item.email
        }));
        resolve(result);
      } catch (error) {
        throw error;
      };
    });
  }, []);
  // Debounce the API call to avoid making too many requests. We only want to fetch the data when the user has stopped typing.
  const debouncedLoadAsyncOptions = useMemo(() => debounce(loadAsyncOptions, 300), [loadAsyncOptions]);

  const goBack = (e: any) => {
    e.preventDefault();
    history.push(prefixRoute(ROUTES.Environments));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const systemStatus = await getSystemStatus();
        setIsFullWorkshops(systemStatus.isFullWorkshops());
      setIsFullDeployableDemos(systemStatus.isFullDeployableDemos());
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const [workshop, setWorkshop] = useState<ScheduleWorkshopObj>({
    description: '',
    type: '',
    scheduledDate: '',
    leadSE: name,
    breakoutSEs: [],
    attendees: [],
    grafanaCloudRegion: 'prod-us-west-0',
    config: '{}',
  });

  const [validationErrors, setValidationErrors] = useState({
    description: '',
    type: '',
    scheduledDate: '',
    leadSE: '',
    breakoutSEs: '',
    attendees: '',
    grafanaCloudRegion: '',
    config: '',
  });

  const handleChange = (name: string, value: any) => {
    const errorMessage = validateField(name, value);
    setValidationErrors({ ...validationErrors, [name]: errorMessage });

    if (name === 'breakoutSEs') {
      // We need to extract only the value from the SelectableValue object
      setWorkshop({ ...workshop, breakoutSEs: value.map((item: { value: string }) => item.value)});
    } else {
      setWorkshop(workshop => ({ ...workshop, [name]: value }));
    }

    // Update the form if we've changed the workshop type
    if (name === 'type') {
      if(value.startsWith("deployabledemo") === false && isFullWorkshops) {
        setValidationErrors({ ...validationErrors, type: "No workshop slots available..." });
      } else if (value.startsWith("deployabledemo") && isFullDeployableDemos) {
        setValidationErrors({ ...validationErrors, type: "No Application Environment slots available..." });
      } else {
        setValidationErrors({ ...validationErrors, type: "" });
      }

      setSelectedTemplate(workshopTypes.find((type) => type.value === value) || null);
      setIsDeployableDemo(value.startsWith("deployabledemo"));
      handleDateChange(value.startsWith("deployabledemo") ? new Date() : null);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDateChange = (value: any) => {
    // This check exists because when the user selects a deployabledemo we force the date to be today.
    // however, if they change from deployabledemo to a workshop the date may not have yet been set 
    // therefore we pass in null within handleChange as we don't want to assume the date for the workshop
    // we can't generate an expiry date based off a null value so it's safer to just ignore everything if 
    // passed in value is null
   
    setScheduledDate(value);
  
    if (value === null) {
      setExpiryDate(value);
    } else {
    setExpiryDate(calculateExpiryDate(value));
    setExpiryDate(calculateExpiryDate(value));

      setExpiryDate(calculateExpiryDate(value));

      const errorMessage = validateField('scheduledDate', value);
      setValidationErrors({ ...validationErrors, scheduledDate: errorMessage });

      const date = new DateObject({ date: value }).format('YYYY-MM-DD');
      setWorkshop(workshop => ({ ...workshop, scheduledDate: date }));
    } 
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleCancelSubmit = () => {
    setShowConfirmationModal(false); // hide the confirmation modal
  };

  // Calculate fields to show the capacity used / remaining
  const capacity = selectedTemplate?.max_users || 0;
  const seatsUsed = 1 + facilitators.length + (workshop.attendees?.length || 0);
  const capacityBarLabel = `${seatsUsed} / ${capacity} max`;
  const capacityBarBackground = useMemo(
    () => {
      if (seatsUsed > capacity) {
        return '#D10E5C';
      } else {
        return '#1A7F4B';
      }
    },
    [seatsUsed, capacity]
  );
  const capacityUsedPercentage = (seatsUsed / capacity) * 100;
  const isOverCapacity = seatsUsed > capacity;

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set the loading state to true before processing the file
    setIsLoading(true);
    // Clear previously selected file and any error messages
    setValidationErrors({ ...validationErrors, attendees: '' });
    setSelectedFile(null);

    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    try {
      const validEmails = await validateEmailAddressesFile(file);
      setWorkshop({ ...workshop, attendees: validEmails });

      // Set the selected file and its name for display
      setSelectedFile(file);
      setSelectedFileName(file.name);

      const errorMessage = '';
      setValidationErrors({ ...validationErrors, attendees: errorMessage });
      console.log(validEmails);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.error(error.message);
      const errorMessage = 'Invalid file format please try again...';
      setValidationErrors({ ...validationErrors, attendees: errorMessage });
    }
  };

  const isFormValid = (updatedWorkshop: ScheduleWorkshopObj) => {
    const requiredFields = ['description', 'type', 'scheduledDate', 'leadSE', 'grafanaCloudRegion'];
    const hasEmptyField = requiredFields.some((field) => !updatedWorkshop[field]);
    const hasErrors = Object.values(validationErrors).some((error) => error !== '');

    if (hasEmptyField) {
      if (workshop.type === '') {
        const errorMessage = 'Press select a workshop type...';
        setValidationErrors({ ...validationErrors, type: errorMessage });
      } else if (workshop.scheduledDate === '') {
        const errorMessage = 'Press select a workshop date...';
        setValidationErrors({ ...validationErrors, scheduledDate: errorMessage });
      } else if (workshop.description === '') {
        const errorMessage = 'Enter the purpose of this environment';
        setValidationErrors({ ...validationErrors, description: errorMessage });
        setTouched({ ...touched, description: true });
      } else if (workshop.grafanaCloudRegion === '') {
        const errorMessage = 'Select a Grafana Cloud region...';
        setValidationErrors({ ...validationErrors, grafanaCloudRegion: errorMessage });
      } else if (workshop.leadSE === '') {
        const errorMessage = 'Enter the lead SEs email address...';
        setValidationErrors({ ...validationErrors, leadSE: errorMessage });
      }
      return { isValid: false, error: 'All fields are required...' };
    }

    if (hasErrors) {
      return { isValid: false, error: 'There are errors in the form fields...' };
    }

    return { isValid: true, error: '' };
  };

  const [touched, setTouched] = useState({
    description: false,
  });

  const [submitFailed, setSubmitFailed] = useState(false);
  const [failedReason, setFailedReason] = useState('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if(!isDeployableDemo && isFullWorkshops) {
      setFailedReason("No workshop slots available...");
      setSubmitFailed(true);
      return workshop;
    }

    if(isDeployableDemo && isFullDeployableDemos) {
      setFailedReason("No Application Environment slots available...");
      setSubmitFailed(true);
      return workshop;
    }

    const validation = isFormValid(workshop);

    if (validation.isValid) {
      setShowConfirmationModal(true);
      setIsSubmitting(true);
      setFailedReason('');
      setSubmitFailed(false);
    } else {
      setFailedReason(validation.error);
      setSubmitFailed(true);
    }

    return workshop;
  };

  const handleConfirmSubmit = async () => {
    setShowConfirmationModal(false);
    try {
      workshop.config = JSON.parse(customConfig);
      const createdWorkshop = await createWorkshop(workshop);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Workshop queued for creation. Please allow 1-3 hours for provisioning to complete.'],
      });
      onWorkshopCreated(createdWorkshop);
    } catch (error) {
      setIsSubmitting(false);
      setFailedReason(`${error}`);
      setSubmitFailed(true);
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFull = () => {
    return (isDeployableDemo && isFullDeployableDemos) || (!isDeployableDemo && isFullWorkshops)
  };

  const renderFormCapacityAlert = () => {
    if(isDeployableDemo && isFullDeployableDemos) {
      return <DeployableDemoCapacityAlert />
    } else if (!isDeployableDemo && isFullWorkshops) {
      return <WorkshopCapacityAlert />
    } else {
      return null;
    }
    
  };

  return (
    <>
      {!(authenticatedUser && hasPermission(authenticatedUser, 'environment:create')) ? (
        <Alert title="Permissions needed" severity="warning">
          Oops! We can&apos;t find your login in our approved users list. Please reach out to the <code>#field-engineering</code> channel in Slack for access.
        </Alert>
      ) : (
        <>
          {submitFailed && (
            <div style={{ paddingTop: '25px', paddingBottom: '25px' }}>
              <Alert title={failedReason} severity="error" />
            </div>
          )}
          <form onSubmit={handleSubmit} style={{ maxWidth: '600px' }}>
            <FieldSet>
              <Field
                label="Type"
                invalid={validationErrors.type !== ''}
                error={validationErrors.type}
                required={true}
              >
                <Select
                  options={workshopTypes.map((type) => ({ label: type.label, value: type.value, imgUrl: type.imgUrl }))}
                  value={workshopTypes.find((option: any) => option.value === workshop.type)}
                  onChange={(selected) => handleChange('type', selected.value)}
                  placeholder="Select one"
                />
              </Field>

              {selectedTemplate != null ? (
                <Card>
                  <Card.Heading>
                    {selectedTemplate.label}
                  </Card.Heading>
                  <Card.Description>
                    {selectedTemplate.description}
                  </Card.Description>
                  <Card.Meta>{[`Maximum capacity: ${selectedTemplate.max_users}`]}</Card.Meta>
                  <Card.Figure>
                    <img
                      height="40"
                      src={selectedTemplate.imgUrl}
                      width="40"
                    />
                  </Card.Figure>
                  <Card.SecondaryActions>
                    <a href={selectedTemplate.github} target="_blank" rel="noreferrer"><IconButton
                      name="github"
                      tooltip="View breakout repository"
                    /></a>
                  </Card.SecondaryActions>
                </Card>
              ) : (
                <Alert title="Please select a workshop type" severity="info" />
              )}
            </FieldSet>
            { (isFull()) ? (renderFormCapacityAlert()) :(
              <div>
                <FieldSet>
                  <Field label="Purpose"
                    description="Enter the purpose of this environment. This is not shared with users, but will be shown in the Environments list."
                    required={true}
                    invalid={validationErrors.description !== '' && touched.description}
                    error={validationErrors.description}>
                      <Input
                        value={workshop.description}
                        placeholder="Workshop for ACME Inc, Jon's practice environment, ..."
                        onChange={(event) => handleChange('description', event.currentTarget.value)}
                        onBlur={() => setTouched({ ...touched, description: true })}
                    />
                  </Field>
                  { isDeployableDemo === false && (
                    <Field
                      label="Event date"
                      description={isDeployableDemo}
                      invalid={validationErrors.scheduledDate !== ''}
                      error={validationErrors.scheduledDate}
                      required={true}
                    >
                      <DatePickerWithInput
                        value={scheduledDate || undefined }
                        minDate={minDate}
                        placeholder="Select the date..."
                        onChange={(date: any) => handleDateChange(date)}
                        width={20}
                        closeOnSelect={true}
                      />
                    </Field>
                  )}
                
                  <Field
                    label="Deletion date"
                    description="The environment will be automatically deleted on the following date."
                  >
                    <DatePickerWithInput
                      disabled
                      value={expiryDate || undefined}
                      onChange={(date: any) => setExpiryDate(date)}
                      placeholder=""
                      width={20}
                      closeOnSelect={true}
                    />
                  </Field>
                </FieldSet>

                <FieldSet label="Users">
                  <>
                    {selectedTemplate != null ? (
                      <Field
                        label="Seats used / capacity"
                        description={`This workshop has a maximum capacity of ${capacity} users, including the owner.`}
                      >
                        <>
                          <ProgressBar width='100%' animateOnRender={false} baseBgColor="rgba(204, 204, 220, 0.16)" completed={capacityUsedPercentage} customLabel={capacityBarLabel} bgColor={capacityBarBackground} labelAlignment='outside' labelSize='13px' customLabelStyles={{ flex: 'none' }}></ProgressBar>
                          {isOverCapacity && 
                            <FieldValidationMessage>Too many users for this workshop. Please remove some users.</FieldValidationMessage>
                          }
                        </>
                      </Field>
                    ) : (
                      <Field
                        label="Seats used / capacity"
                      >
                        <p><Icon name="info-circle" size="sm" style={{ marginLeft: 4, cursor: 'pointer' }} /> Please select a workshop type first to see capacity.</p>
                      </Field>
                    )}

                    <Field
                      label="Owner"
                      description="An environment will be created for the user given here."
                      invalid={validationErrors.leadSE !== ''}
                      error={validationErrors.leadSE}
                    >
                      <Input
                        value={workshop.leadSE}
                        onChange={(event) => handleChange('leadSE', event.currentTarget.value)}
                        disabled
                      />
                    </Field>
                    {isDeployableDemo === false && (
                      <Field
                        label="Facilitators/Co-presenters"
                        description="Enter the @grafana.com email addresses of any Grafanistas who will need access to this environment. You don't need to add yourself here."
                        invalid={validationErrors.breakoutSEs !== ''}
                        error={validationErrors.breakoutSEs}
                      >
                        <AsyncMultiSelect
                          loadOptions={debouncedLoadAsyncOptions}
                          allowCustomValue={true}
                          createOptionPosition="first"
                          placeholder='ron.barcelo@grafana.com'
                          onCreateOption={v => {
                            const customValue: SelectableValue<string> = {
                              value: v,
                              label: v
                            };
                            const newFacilitators = [...facilitators, customValue];
                            setFacilitators(newFacilitators);
                            handleChange('breakoutSEs', newFacilitators);
                          }}
                          defaultOptions
                          value={facilitators}
                          onChange={selected => {
                            setFacilitators(selected);
                            handleChange('breakoutSEs', selected);
                          }}
                        />
                      </Field>
                    )}

                    {selectedFile ? (
                      <Field
                        label="See Uploaded File Details Below"
                        invalid={validationErrors.attendees !== ''}
                        error={validationErrors.attendees}
                      >
                        <Card>
                          <Card.Meta>
                            <h5>
                              <Icon name="file-blank" /> {selectedFileName}
                            </h5>

                            <Button fill="text" size="sm" onClick={handleToggle}>
                              view the {workshop.attendees?.length} attendees...
                            </Button>

                            {isOpen && (
                              <Modal title="Attendee List" isOpen={isOpen} onDismiss={handleToggle}>
                                <div style={{ flex: '1', overflowY: 'auto', maxHeight: '50vh' }}>
                                  {workshop.attendees?.map((email, index) => (
                                    <div key={email} style={{ paddingBottom: '5px' }}>
                                      {email}
                                    </div>
                                  ))}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                  <Button variant="secondary" onClick={handleToggle}>
                                    Close
                                  </Button>
                                </div>
                              </Modal>
                            )}
                            <IconButton
                              variant="destructive"
                              size="md"
                              name="trash-alt"
                              aria-label="Clear attendee list"
                              onClick={() => {
                                setSelectedFile(null);
                                setSelectedFileName('');
                                setWorkshop({ ...workshop, attendees: [] }); // Clear the attendee list
                              }}
                            />
                          </Card.Meta>
                        </Card>
                      </Field>
                    ) : (
                      <Field
                        label="Regular users"
                        description={
                          <div>
                            Provide a file containing the email address of each user who will be accessing this environment. For a workshop, this is typically the list of confirmed participants.
                            <Tooltip
                              content={
                                <>
                                  <u>File Format Option 1</u>
                                  <br />
                                  john@gmail.com,jane@hotmail.com,tom@gmail.com
                                  <br />
                                  <div style={{ paddingTop: '15px' }}>
                                    <u>File Format Option 2</u>
                                    <br />
                                    john@gmail.com
                                    <br />
                                    jane@hotmail.com
                                    <br />
                                    tom@gmail.com
                                  </div>
                                </>
                              }
                            >
                              <Icon name="info-circle" size="sm" style={{ marginLeft: 4, cursor: 'pointer' }} />
                            </Tooltip>
                          </div>
                        }
                        invalid={validationErrors.attendees !== ''}
                        error={validationErrors.attendees}
                      >
                        <div style={{ paddingTop: '5px' }}>
                          <input type="file" style={{ display: 'none' }} onChange={handleUpload} ref={inputElement} />
                          <Button
                            icon="upload"
                            fill="outline"
                            size="md"
                            style={{ height: '37px' }}
                            variant="primary"
                            onClick={() => inputElement.current?.click()}
                            disabled={isLoading}
                          >
                            Click Here to Upload File
                          </Button>
                          {isLoading && <Spinner />}
                        </div>
                      </Field>
                    )}

                    {/* confirmation modal */}
                    {showConfirmationModal && (
                      <Modal title="" isOpen={showConfirmationModal} onDismiss={() => setShowConfirmationModal(false)}>
                        <VerticalGroup spacing="lg">
                          <h1 style={{ textDecoration: 'bold' }}>Are all of the workshop details correct?</h1>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Workshop Name:
                            </span>{' '}
                            {workshop.name}
                          </h5>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Workshop Type:{' '}
                            </span>
                            {workshop.type}
                          </h5>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Workshop Date:{' '}
                            </span>
                            {workshop.scheduledDate}
                          </h5>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Lead SE:{' '}
                            </span>
                            {workshop.leadSE}
                          </h5>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Breakout SEs:{' '}
                            </span>
                            {workshop.breakoutSEs?.length}
                          </h5>
                          <h5>
                            <span style={{ fontSize: '90%', textDecoration: 'underline', paddingRight: '5px' }}>
                              Attendees:{' '}
                            </span>
                            {workshop.attendees?.length}
                          </h5>

                          <HorizontalGroup spacing="lg" justify="flex-end" align="flex-end">
                            <Button variant="secondary" size="lg" fill="outline" onClick={handleCancelSubmit}>
                              No - Back to Edit
                            </Button>
                            <Button variant="success" size="lg" onClick={handleConfirmSubmit}>
                              Yes - Schedule the Workshop
                            </Button>
                          </HorizontalGroup>
                        </VerticalGroup>
                      </Modal>
                    )}
                  </>
                </FieldSet>
                <FieldSet label="Advanced configuration">
                  <Field
                    label="Grafana Cloud region"
                    description="Select the Grafana Cloud region where stacks will be created."
                    invalid={validationErrors.grafanaCloudRegion !== ''}
                    error={validationErrors.grafanaCloudRegion}
                  >
                    <Select
                      options={grafanaCloudRegions}
                      value={grafanaCloudRegions.find((option: any) => option.value === workshop.grafanaCloudRegion)}
                      onChange={(selected) => handleChange('region', selected.value)}
                    />
                  </Field>

                  <Field
                    label="Custom configuration"
                    description="If you've been given some JSON code to customize the workshop environment, paste it here."
                  >
                    {isShowCustomConfig ? (
                      <>
                        <CodeEditor
                          language="json"
                          value={customConfig}
                          onChange={(event) => {
                            setCustomConfig(event);
                          }}
                          showMiniMap={false}
                          width={500}
                          height={300}
                        />
                      </>
                    ) : (
                      <Button variant="secondary" onClick={() => setIsShowCustomConfig(true)}>
                        Set custom configuration
                      </Button>
                    )}
                  </Field>
                </FieldSet>
              </div>
            )}

            <HorizontalGroup spacing="md">
              <Button size="lg" fill="outline" style={{ height: '43px' }} variant="secondary" onClick={goBack}>
                Cancel
              </Button>
              <Button size="lg" type="submit" style={{ height: '43px' }} variant="primary" 
                disabled={isFull() || isOverCapacity}>
                Submit
              </Button>
              {isSubmitting && <Spinner />}
            </HorizontalGroup>
          </form>
        </>
      )}
    </>
  );
};

export default ScheduleWorkshopForm;
