export interface ScheduleWorkshopObj {
  name?: string;
  description: string;
  type: string;
  scheduledDate?: string;
  leadSE?: string;
  breakoutSEs?: string[];
  attendees?: string[];
  [index: string]: any;
  grafanaCloudRegion: string;
  config: any;
}

export const workshopTypes = [
  {
    label: 'Empty Grafana Cloud Stacks',
    value: 'grafanacloud',
    imgUrl: '/public/app/plugins/datasource/loki/img/grafana_icon.svg',
    max_users: 80,
    description: 'Provisions Grafana Cloud stacks with simple username/password based authentication.',
    doc: '',
    github: '',
    slack: '',
    gdrive: '',
  },
  {
    label: 'Extreme Grafana Dashboard Makeover Workshop',
    value: 'extremedashboard',
    imgUrl: '/public/app/plugins/panel/barchart/img/barchart.svg',
    max_users: 80,
    description: 'Provisions Grafana Cloud instances, and installs library panels and data sources (including the LokiNGINX data source) for the workshop.',
    doc: 'https://docs.google.com/document/d/1ClkBC10jSlwFt83McgPQMdhghm1Fj-FBWcXxJiIo1AU/edit',
    github: 'https://github.com/grafana/extreme-dashboard-makeover-breakouts',
    slack: 'https://raintank-corp.slack.com/archives/C040C2BQ8BC',
    gdrive: 'https://docs.google.com/presentation/d/1dLX0RMkEcOlRpaLO12pz0Zg4T5Dc-SlFgr4kTA39upU/edit#slide=id.g12499d2ef29_0_39',
  },
  {
    label: 'Observability Workshop',
    value: 'observability',
    imgUrl: '/public/plugins/fieldeng-brokkr-app/assets/images/kubernetes.svg',
    max_users: 60,
    description: 'Provisions Grafana Cloud instances, Kubernetes clusters, and a web terminal. Users learn how to configure and deploy Grafana Agent to a Kubernetes cluster, and how to use Synthetics to observe a demo app.',
    doc: 'https://drive.google.com/drive/u/0/folders/1U2D3GoI4Wu4f5BFCT25UxM7u2sz9fP8e',
    github: 'https://github.com/grafana/intro-to-observability-breakout',
    slack: 'https://app.slack.com/client/T02S4RCS0/C02E4KSM736',
    gdrive: 'https://drive.google.com/open?id=1ZOqRrssLz3VnJjbj4kIimmLsxHIKWI88&usp=drive_copy',
  },
  {
    label: 'Tracing Workshop',
    value: 'tracing',
    imgUrl: '/public/app/plugins/datasource/tempo/img/tempo_logo.svg',
    max_users: 60,
    description: 'Provisions Grafana Cloud instances, Kubernetes clusters, the TNS app, and a web terminal. Users learn about traces, how to work with them in Grafana and how to instrument code to generate them.',
    doc: 'https://docs.google.com/document/d/1oxQ2ZC8l7Si-YAJjffwv50z3kD9UdqPqjk3j1EyVigQ/edit',
    github: 'https://github.com/grafana/tracing-workshop-breakout',
    slack: 'https://raintank-corp.slack.com/archives/C037K320HK5',
    gdrive: 'https://docs.google.com/presentation/d/1H8Cz7GAf89O84PMWfZqEVhQ3iQ19aoMcxQLA4tgPipk/edit#slide=id.gce153c388b_0_1983',
  },
  {
    label: 'Application Environment',
    value: 'deployabledemo-appenv',
    imgUrl: '/public/plugins/fieldeng-brokkr-app/assets/images/kubernetes.svg',
    max_users: 42,
    description: 'Provisions an isolated Grafana Cloud Stack, Kubernetes cluster, instance of the Application Environment and associated other monitoring for the demo app environment.',
    doc: 'https://grafana-intranet--simpplr.visualforce.com/apex/simpplr__app?u=/site/a145f000001f7m7AAA/page/a125f000001ArZHAA0',
    github: 'https://github.com/grafana/appenv',
    slack: 'https://raintank-corp.slack.com/archives/C01CGEAR8H4',
    gdrive: '',
  },
];

export type WorkshopType = {
  label: string;
  value: string;
  imgUrl?: string; // Used by the dropdown
  max_users: number;
  description: string;
  doc: string;
  github: string;
  slack: string;
  gdrive: string;
};

export const grafanaCloudRegions = [
  {
    label: 'Grafana Cloud - AWS US West',
    value: 'prod-us-west-0',
    description: 'prod-us-west-0',
  }
];

export function getWorkshopDetails(value: string): WorkshopType | undefined {
  return workshopTypes.find((type) => type.value === value);
}
