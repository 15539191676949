import React from 'react';
import { Badge } from '@grafana/ui';

interface EnvironmentStatusBadgeProps {
  status: string | undefined;
}

export const EnvironmentStatusBadge: React.FC<EnvironmentStatusBadgeProps> = ({ status }) => {
  const displayStatus = status && status.charAt(0).toUpperCase() + status.slice(1);
  
  switch (displayStatus) {
    case 'Provisioned':
      return <Badge text={'Active'} color="green" icon="check" tooltip='The environment is active and ready for use'/>;
    case 'Failed':
      return <Badge text={displayStatus} color="red" icon="exclamation-circle" />;
    case 'Provisioning':
      return <Badge text={displayStatus} color="orange" icon="fa fa-spinner" />;
    case 'Deleting':
      return <Badge text={displayStatus} color="orange" icon="fa fa-spinner" />;
    default:
      return null;
  }
};
