
  export const columns =  [
    {
        name: 'UUID',
        selector: (row: { uuid: any; }) => row.uuid,
        omit:true,
        id:(row: { uuid: any; }) => row.uuid,
    },
    {
      name: 'NAME',
      selector: (row: { name: any; }) => row.name,
      sortable:true,
      grow: 1,
      cell: (row: { name: any; }) => row.name,
    },
    {
      name: 'EMAIL',
      selector: (row: { email: any; }) => row.email,
      sortable:true,
      grow:2,
      cell: (row: { email: any; }) => row.email,
    },
    {
        name: 'USERNAME',
        selector: (row: { username: any; }) => row.username,
        omit:true,
    },
    {
        name: 'PASSWORD',
        selector: (row: { password: any; }) => row.password,
        omit:true,
    },
];
