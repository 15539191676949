import React from 'react';
import { format, utcToZonedTime } from 'date-fns-tz';

interface FormattedDateProps {
  date: string;
  formatString?: string;
  timeZone?: string;
}

const FormattedDate: React.FC<FormattedDateProps> = ({ date, formatString = 'MMMM do', timeZone = 'UTC' }) => {
  if (!date) {
    return <span>-</span>;
  }
  const parsedDate = utcToZonedTime(date, timeZone);
  const formattedDate = format(parsedDate, formatString, { timeZone });

  return <span>{formattedDate}</span>;
};

export default FormattedDate;
