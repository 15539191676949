import { WorkshopState } from 'components/CustomTable/subTableComponents/ColorMapping';
import FormattedDate from 'components/CustomTable/subTableComponents/FormattedDate';
import React from 'react';
import { HorizontalGroup, Icon, Tag } from '@grafana/ui';
import { prefixRoute, ROUTES } from 'utils/routing.util';


export const workshopColumns = [
  {
      name: 'UUID',
      selector: (row: { uuid: any; }) => row.uuid,
      omit:true,
      id:(row: { uuid: any; }) => row.uuid,
  },
  {
      name: <HorizontalGroup><Icon name="dashboard"/>ID</HorizontalGroup>,
      selector: (row: { name: any; }) => row.name,
      sortable:true,
      cell: (row: { name: any; uuid: any }) => (
            <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
              <div style={{ whiteSpace: 'normal' }}>{row.name}</div>
            </a>
      ),
  },
  {
      name: <HorizontalGroup><Icon name="dashboard"/>DESCRIPTION</HorizontalGroup>,
      selector: (row: { description: any; }) => row.description,
      sortable:true,
      grow: 2,
      cell: (row: { description: any; uuid: any }) => (
            <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
              <div style={{ whiteSpace: 'normal' }}>{row.description}</div>
            </a>
      ),
  },
  {
      name: <HorizontalGroup><Icon name="horizontal-align-right"/>TYPE</HorizontalGroup>,
      selector: (row: { type: any; }) => row.type,
      sortable:true,
      grow: 2,
      cell: (row: { type: any; uuid: any; }) => (
        <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
          <Tag name={row.type}></Tag>
        </a>
  )
  },
  {
      name: <HorizontalGroup><Icon name="calendar-alt"/>EXPIRY DATE</HorizontalGroup>,
      selector: (row: { deprovisionDate: any; }) => row.deprovisionDate,
      sortable:true,
      cell: (row: { deprovisionDate: any; uuid: any; }) => (
          <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
            {row.deprovisionDate ? <FormattedDate date={row.deprovisionDate} formatString="yyyy-MM-dd HH:mm:ss" /> : 'Never'}
          </a>
      )
  },
  {
      name: <HorizontalGroup><Icon name="user"/>OWNER</HorizontalGroup>,
      selector: (row: { leadSEAttendee: any; }) => row.leadSEAttendee.email,
      sortable:true,
      cell: (row: { leadSEAttendee: any; uuid: any; }) => (
        <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
          {row.leadSEAttendee.email}
        </a>
  )
  },
  {
      name: <HorizontalGroup><Icon name="brackets-curly"/>INFRASTRUCTURE STATUS</HorizontalGroup>,
      selector: (row: { state: any; }) => row.state,
      sortable:true,
      cell: (row: { state: any; uuid: any; }) => (
        <a href={`${prefixRoute(ROUTES.Environments)}/${row.uuid}`} rel="noopener noreferrer">
          <WorkshopState value={row.state}/> 
        </a>
  )
  },
];
