import React, {  } from 'react';
import { Participant, Workshop } from 'types/workshop';
import CustomTable from 'components/CustomTable/CustomTable';
import { columns } from 'components/CustomTable/columnDefinitions/deployableDemoUsers';
import { getAppEvents } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';
import { Button } from '@grafana/ui';

type Props = {
  workshop: Workshop;
};

const DeployableDemoUserDetails: React.FC<Props> = ({ workshop }) => {
  const appEvents = getAppEvents();
  let attendees = [workshop.leadSEAttendee, ...workshop.breakoutSEAttendees ?? [], ...workshop.workshopAttendees ?? [] ].filter((attendee): attendee is Participant => !!attendee);

  const copyToClipboard = async (input: any, type: string) => {
    let text = '';

    if (type === 'text') {
      text = input;
    } else if (type === 'workshopObj') {
      text = `Email: ${input.email}
        Grafana Instance: https://${workshop.name}.grafana.net
        Username: ${input.username}
        Password: ${input.password}`;
    }

    try {
      await navigator.clipboard.writeText(text);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Copied to clipboard'],
      });
    } catch (err) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: ['Failed to copy text to clipboard.'],
      })
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          padding: '1rem',
          width: '60%',
          borderRadius: '5px',
          marginRight: '20px',
        }}
      >
      
        <div>
          <CustomTable
            key={`${attendees?.length}`}
            data={attendees || []}
            columns={columns}
            searchPlaceholder="Search Logins..."
            itemsPerPage={10}
          />
        </div>
        
        <div>
          <Button
            variant="secondary"
            icon="clipboard-alt"
            onClick={() =>
              copyToClipboard(
                attendees
                  .map((item) => `${item.email} | ${item.name} | ${item.password}`)
                  .join('\n'),
                'text'
              )
            }
          >
            Copy all logins to Clipboard
          </Button>
        </div>
      </div>
  </div>
  );
};

export default DeployableDemoUserDetails;
